'use client';

import { product_display_style } from '@/src/containers/main/constants';
import type { LocaleProps } from '@costcolabs/forge-digital-components';
import {
    CriteoVideoSpotlight,
    findCriteoPlacement,
    useCheckScreen,
    useCriteo,
} from '@costcolabs/forge-digital-components';

export const VideoSpotlightClient = ({
    lang,
    position,
    pageType,
}: {
    lang: LocaleProps;
    position: string;
    pageType: string | undefined;
}) => {
    const { isMobile } = useCheckScreen();
    const { searchPageData } = useCriteo();

    // do not display this component above search results when the device is desktop/tablet
    if (position === 'filters' && isMobile) {
        return null;
    }

    if (position === 'grid' && !isMobile) {
        return null;
    }

    const videoSpotlightPlacementKey =
        pageType === 'category'
            ? `viewCategory_API_${isMobile ? 'mobile' : 'desktop'}-Video`
            : `viewSearchResults_API_${isMobile ? 'mobile' : 'desktop'}-Video`;

    const videoSpotlightPlacement = findCriteoPlacement(
        videoSpotlightPlacementKey,
        searchPageData?.placements
    );

    const ParentSKUs =
        videoSpotlightPlacement?.products.map(
            ({ ParentSKU }: { ParentSKU: string }) => ParentSKU
        ) || [];

    if (!videoSpotlightPlacement) {
        return null;
    } else {
        return (
            <CriteoVideoSpotlight
                contentStackEntry={{
                    criteo_placement_name: `Video`,
                    product_display_style,
                }}
                locale={lang}
                products={videoSpotlightPlacement?.products}
                placement={videoSpotlightPlacement}
                ParentSKUs={ParentSKUs}
                variant={'rail'}
                productTileSx={{
                    minHeight: '100%',
                    paddingRight: `2px`,
                }}
            />
        );
    }
};
