'use client';

import { ReactNode, useEffect, useRef, useState } from 'react';

import { FilterGroup } from '@/components/FilterGroup';
import {
    IN_WAREHOUSE_BUCKET_VALUE,
    USE_WAREHOUSE_INVENTORY_BUCKET_VALUE,
} from '@/constants/index';
import { SearchServiceProps } from '@/services/search/types';
import {
    GRS_DELIVERY_LOCATION_LABEL_KEY,
    GRS_WAREHOUSE_LABEL_KEY,
} from '@/src/components/SearchResultsFacets/grs.constants';
import {
    FacetBucketNormalized,
    FacetNormalized,
} from '@/src/components/SearchResultsFacets/types';
import { getKeyValue } from '@/src/services/content/business.lib';
import type {
    ContentStackEntryDataProps,
    GRSFacetConfigProperties,
} from '@/types/contentStack';
import { PageType } from '@/types/pages/search';
import { Accordion } from '@costcolabs/forge-components';
import { SpaceLg, SpaceMd, SpaceXs } from '@costcolabs/forge-design-tokens';
import type { LocaleProps } from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';

export function ProgramEligibilityFilterGroupV2({
    facet,
    bopiwFacet,
    buyInWarehouseFacet,
    config,
    grsFacetConfig,
    WarehouseSelectorComponent,
    DeliveryLocationSelectorComponent,
    locale,
    pageType,
    searchService,
}: {
    facet: FacetNormalized;
    bopiwFacet?: FacetNormalized;
    buyInWarehouseFacet?: FacetNormalized;
    config: ContentStackEntryDataProps;
    grsFacetConfig:
        | ContentStackEntryDataProps<GRSFacetConfigProperties>
        | undefined;
    WarehouseSelectorComponent: ReactNode;
    DeliveryLocationSelectorComponent: ReactNode;
    locale: LocaleProps;
    pageType: PageType;
    searchService: SearchServiceProps;
}) {
    const [isDeliveryExpanded, setIsDeliveryExpanded] = useState(true);
    const [isWarehouseExpanded, setIsWarehouseExpanded] = useState(true);
    const accordionRef = useRef<HTMLDivElement | null>(null);
    const deliveryLocationLabel = grsFacetConfig
        ? getKeyValue(GRS_DELIVERY_LOCATION_LABEL_KEY, grsFacetConfig)
        : '';
    const warehouseLabel = grsFacetConfig
        ? getKeyValue(GRS_WAREHOUSE_LABEL_KEY, grsFacetConfig)
        : '';

    useEffect(() => {
        const accordionInnerContent = accordionRef.current?.querySelector(
            "div.MuiAccordion-region[role='region']"
        );

        if (accordionInnerContent) {
            accordionInnerContent.removeAttribute('role');
        }
    }, []);

    const facetBucketsInWarehouseRemoved: FacetBucketNormalized[] = [];

    facet.buckets.forEach((bucket: FacetBucketNormalized) => {
        const bucketValLower = String(bucket.val).toLowerCase();
        if (
            bucketValLower !== IN_WAREHOUSE_BUCKET_VALUE &&
            bucketValLower !== USE_WAREHOUSE_INVENTORY_BUCKET_VALUE
        ) {
            facetBucketsInWarehouseRemoved.push(bucket);
        }
    });

    const facetInWarehouseRemoved = {
        ...facet,
        buckets: facetBucketsInWarehouseRemoved,
    };

    return (
        <>
            <Accordion
                accordionSummarySx={{
                    '&.MuiButtonBase-root': {
                        '&.MuiAccordionSummary-root': {
                            padding: `${SpaceMd} 0 ${SpaceXs} 0`,
                        },
                    },
                }}
                title={deliveryLocationLabel}
                // @ts-ignore Accordion doesn't export this type
                variant="primary"
                isExpanded={isDeliveryExpanded}
                onChange={() => setIsDeliveryExpanded(!isDeliveryExpanded)}
                uniqueId={facet.facetKey}
                ref={accordionRef}
            >
                <Box
                    sx={{
                        paddingBottom: SpaceLg,
                        '& button': {
                            fontSize: 14,
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {DeliveryLocationSelectorComponent}
                </Box>
                <FilterGroup
                    facet={facetInWarehouseRemoved}
                    config={config}
                    locale={locale}
                    pageType={pageType}
                    searchService={searchService}
                />
            </Accordion>
            <Accordion
                accordionSummarySx={{
                    '&.MuiButtonBase-root': {
                        '&.MuiAccordionSummary-root': {
                            padding: `${SpaceMd} 0 ${SpaceXs} 0`,
                        },
                    },
                }}
                title={warehouseLabel}
                // @ts-ignore Accordion doesn't export this type
                variant="primary"
                isExpanded={isWarehouseExpanded}
                onChange={() => setIsWarehouseExpanded(!isWarehouseExpanded)}
                uniqueId={facet.facetKey}
                ref={accordionRef}
            >
                <Box
                    sx={{
                        paddingBottom: SpaceLg,
                        '& button': {
                            fontSize: 14,
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {WarehouseSelectorComponent}
                </Box>
                {buyInWarehouseFacet ? (
                    <FilterGroup
                        facet={buyInWarehouseFacet}
                        config={config}
                        locale={locale}
                        pageType={pageType}
                        searchService={searchService}
                    />
                ) : (
                    <></>
                )}
                {bopiwFacet ? (
                    <FilterGroup
                        facet={bopiwFacet}
                        config={config}
                        locale={locale}
                        pageType={pageType}
                        searchService={searchService}
                    />
                ) : (
                    <></>
                )}
            </Accordion>
        </>
    );
}
