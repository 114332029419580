'use client';

import Script from 'next/script';

import { useSearchContext } from '@/src/services/search/SearchProvider';

export const TranscendOverride = () => {
    const { searchResult } = useSearchContext();
    const executeTranscendOverrideScript =
        searchResult?.isAdTargetingEnabled === false;

    if (!executeTranscendOverrideScript) {
        return null;
    }

    return (
        <Script
            data-disable-when-consented="off"
            data-unknown-cookie-policy="block"
            data-unknown-request-policy="block"
            id="TranscendOverride"
        >
            {`  const ADVERTISING_TRACKING_PURPOSES = ['Advertising', 'SaleOfInfo'];
                const override = (event) => {
                    if (ADVERTISING_TRACKING_PURPOSES.some((purpose) => event.purposes.has(purpose))) {
                        event.deny();
                    }
                }
                
                self.airgap?.override(self.airgapLoadEvent, {override});

                self.airgap?.overrideCookies(self.airgapLoadEvent, override);

                self.airgap?.clearCookies(cookie => {
                    return ADVERTISING_TRACKING_PURPOSES.some(purpose =>
                        cookie.purposes.has(purpose)
                    );
                });
            `}
        </Script>
    );
};
