'use client';

import Script from 'next/script';

import { useSearchContext } from '@/services/search/SearchProvider';
import useCheckScreen from '@/src/hooks/useCheckScreen';
import {
    useBrowseContext,
    useQueryParams,
} from '@costcolabs/forge-digital-components';

const OneTagSearchPageEventScriptClient = ({
    partnerId,
    categoryId,
    isCategoryPage,
}: {
    partnerId: string;
    categoryId?: string;
    isCategoryPage?: boolean;
}) => {
    const { criteoUserCookie } = useBrowseContext();
    const { isMobile } = useCheckScreen();
    const { queryParams: searchParams } = useQueryParams();
    const { searchResult, isSearchResultsLoading } = useSearchContext();
    const keywordParam = searchParams.get('keyword');
    const currentPageParam = searchParams.get('currentPage');
    const refineParam = searchParams.get('refine');

    if (!criteoUserCookie || isSearchResultsLoading) {
        return null;
    }

    let placementKey = isCategoryPage ? 'viewCategory' : 'viewSearch';

    let categoryScriptValue = '';
    let itemsScriptValue = '';

    if (categoryId) {
        categoryScriptValue = `category: ${categoryId},`;
        let items = searchResult?.docs?.reduce(
            (
                itemNumbers,
                { group_id, item_fsa_eligible, item_chdi_eligible }
            ) => {
                if (
                    itemNumbers.length < 3 &&
                    group_id &&
                    !item_fsa_eligible &&
                    !item_chdi_eligible
                ) {
                    itemNumbers.push(`"${group_id}"`);
                }

                return itemNumbers;
            },
            []
        );
        if (items?.length) {
            itemsScriptValue = `item: [${items.join(', ')}],`;
        } else {
            itemsScriptValue = `item: [ "None" ],`;
        }
    }

    return (
        <Script id="CriteoOneTagEventsScript" strategy="lazyOnload">
            {`
              if(typeof window !== "undefined") {
                window.criteo_q = window.criteo_q || [];

                const deviceType = ${isMobile} ? "m" : "d";
                const pageId = ${isMobile} ? "${placementKey}Offsite_mobile" : "${placementKey}Offsite_desktop";
              
                const criteoEventsArray = [
                  { event: "setAccount", account: "${partnerId}"},
                  { event: "setEmail", email: "${criteoUserCookie}", hash_method: "sha256" },
                  { event: "setSiteType", type: deviceType},
                  { event: "setRetailerVisitorId", id: "${criteoUserCookie}"},
                  { event: "${isCategoryPage ? placementKey : 'viewSearchResult'}", 
                    ${categoryScriptValue} 
                    ${itemsScriptValue}
                    page_id: pageId,
                    keyword: ${keywordParam ? `"${keywordParam}"` : 'null'}, 
                    filters: ${refineParam ? `"${refineParam}"` : 'null'},
                    page_number: ${currentPageParam ? `"${currentPageParam}"` : 1}
                  }
                ];
                
                if(${process.env.CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION === 'prod'}) {
                  criteoEventsArray.push({ event: "setUat", uat: "1"});
                }
                window.criteo_q.push(criteoEventsArray);
              };
            `}
        </Script>
    );
};

export default OneTagSearchPageEventScriptClient;
