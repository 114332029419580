import ContentStack, { LivePreview, Region } from 'contentstack';

import { ContentStack as ForgeContentStack } from '@costcolabs/forge-digital-components';
import { ConfigStack as ForgeConfigStack } from '@costcolabs/forge-digital-components';

const debug = process.env.DEBUG_CONTENT_STACK_SDK === 'true' ? true : false;

export const BROWSESTACK = ContentStack.Stack({
    api_key: process.env.CONTENTSTACK_BROWSE_API_KEY as string,
    delivery_token: process.env.CONTENTSTACK_BROWSE_DELIVERY_TOKEN as string,
    environment: process.env.CONTENTSTACK_BROWSE_ENVIRONMENT as string,
    region: process.env.CONTENTSTACK_BROWSE_REGION as ContentStack.Region,
    branch: process.env.CONTENTSTACK_BROWSE_BRANCH as string,
    fetchOptions: {
        debug,
        logHandler(level: string, data: any) {
            if (level === 'warning' || level === 'error') {
                console.log(`level: ${level}`);
                console.log(`  data: ${JSON.stringify(data, null, 2)}`);
            }
        },
    },
});

export const BROWSESTACK_LIVEPREVIEW = ContentStack.Stack({
    api_key: process.env.NEXT_PUBLIC_CONTENTSTACK_BROWSE_API_KEY as string,
    delivery_token: process.env
        .NEXT_PUBLIC_CONTENTSTACK_BROWSE_DELIVERY_TOKEN as string,
    environment: process.env
        .NEXT_PUBLIC_CONTENTSTACK_BROWSE_ENVIRONMENT as string,
    region: process.env
        .NEXT_PUBLIC_CONTENTSTACK_BROWSE_REGION as ContentStack.Region,
    branch: process.env.NEXT_PUBLIC_CONTENTSTACK_BROWSE_BRANCH as string,
    live_preview: {
        enable: true,
        preview_token: process.env
            .NEXT_PUBLIC_CONTENTSTACK_BROWSE_PREVIEW_TOKEN as string,
        host: process.env
            .NEXT_PUBLIC_CONTENTSTACK_BROWSE_PREVIEW_HOST as string,
    } as LivePreview,
});

export const CONFIGSTACK = ContentStack.Stack({
    api_key: process.env.CONTENTSTACK_CONFIG_API_KEY as string,
    delivery_token: process.env.CONTENTSTACK_CONFIG_DELIVERY_TOKEN as string,
    environment: process.env.CONTENTSTACK_CONFIG_ENVIRONMENT as string,
    region: process.env.CONTENTSTACK_CONFIG_REGION as ContentStack.Region,
    branch: process.env.CONTENTSTACK_CONFIG_BRANCH as string,
    fetchOptions: {
        debug,
        logHandler(level: string, data: any) {
            if (level === 'warning' || level === 'error') {
                console.log(`level: ${level}`);
                console.log(`  data: ${JSON.stringify(data, null, 2)}`);
            }
        },
    },
});

export const CONFIGSTACK_LIVEPREVIEW = ContentStack.Stack({
    api_key: process.env.NEXT_PUBLIC_CONTENTSTACK_CONFIG_API_KEY as string,
    delivery_token: process.env
        .NEXT_PUBLIC_CONTENTSTACK_CONFIG_DELIVERY_TOKEN as string,
    environment: process.env
        .NEXT_PUBLIC_CONTENTSTACK_CONFIG_ENVIRONMENT as string,
    region: process.env
        .NEXT_PUBLIC_CONTENTSTACK_CONFIG_REGION as ContentStack.Region,
    branch: process.env.NEXT_PUBLIC_CONTENTSTACK_CONFIG_BRANCH as string,
});

export const configureStacks = (settings?: { fetchOptions: any }) => {
    ForgeContentStack.configure({
        api_key: process.env.CONTENTSTACK_BROWSE_API_KEY as string,
        branch: process.env.CONTENTSTACK_BROWSE_BRANCH as string,
        delivery_token: process.env
            .CONTENTSTACK_BROWSE_DELIVERY_TOKEN as string,
        environment: process.env.CONTENTSTACK_BROWSE_ENVIRONMENT as string,
        fetchOptions: {
            debug,
            logHandler(level: string, data: any) {
                if (level === 'warning' || level === 'error') {
                    console.log(`level: ${level}`);
                    console.log(`  data: ${JSON.stringify(data, null, 2)}`);
                }
            },
            retryLimit: 3,
            ...(settings?.fetchOptions || {}),
        },
        region: process.env.CONTENTSTACK_BROWSE_REGION as Region,
    });

    ForgeConfigStack.configure(
        {
            api_key: process.env.CONTENTSTACK_CONFIG_API_KEY as string,
            branch: process.env.CONTENTSTACK_CONFIG_BRANCH as string,
            delivery_token: process.env
                .CONTENTSTACK_CONFIG_DELIVERY_TOKEN as string,
            environment: process.env.CONTENTSTACK_CONFIG_ENVIRONMENT as string,
            fetchOptions: {
                debug,
                logHandler(level: string, data: any) {
                    if (level === 'warning' || level === 'error') {
                        console.log(`level: ${level}`);
                        console.log(`  data: ${JSON.stringify(data, null, 2)}`);
                    }
                },
                retryLimit: 3,
                ...(settings?.fetchOptions || {}),
            },
            region: process.env.CONTENTSTACK_CONFIG_REGION as Region,
        },
        {
            CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION:
                process.env.CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION,
            CONTENT_ENTRY_ID_CLIENT_CONFIGURATION:
                process.env.CONTENT_ENTRY_ID_CLIENT_CONFIGURATION,
        }
    );
};

export const EXCEPTIONS = [
    'ACL',
    '_in_progress',
    'created_at',
    'created_by',
    '_metadata',
    'tags',
    'updated_by',
    'publish_details',
];
