'use client';

import { useEffect, useRef, useState } from 'react';

import { FilterGroup } from '@/components/FilterGroup';
import { StyledFieldset } from '@/components/SearchResultsFacets/styles';
import {
    ITEM_CATEGORY_FACET_KEY,
    ITEM_LOCATION_FACET_KEY,
    ITEM_PROGRAM_FACET_KEY,
} from '@/constants/index';
import type { SearchServiceProps } from '@/services/search/types';
import {
    GRS_BUY_IN_WAREHOUSE_FACET_KEY,
    GRS_CATEGORY_INFO_FACET_KEY,
    GRS_PROGRAM_TYPES_FACET_KEY,
} from '@/src/components/SearchResultsFacets/grs.constants';
import {
    BreadcrumbNormalized,
    FacetNormalized,
} from '@/src/components/SearchResultsFacets/types';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { PageType } from '@/types/pages/search';
import { Accordion } from '@costcolabs/forge-components';
import type { LocaleProps } from '@costcolabs/forge-digital-components';

export function FacetsAccordion({
    categoryUrlPath,
    facet,
    facetConfig,
    locale,
    pageType,
    categoryId,
    breadcrumb,
    searchService,
}: {
    categoryUrlPath: string | undefined;
    facet: FacetNormalized;
    facetConfig: ContentStackEntryDataProps;
    locale: LocaleProps;
    pageType: PageType;
    breadcrumb?: BreadcrumbNormalized;
    searchService: SearchServiceProps;
    categoryId?: string;
}) {
    const [isExpanded, setIsExpanded] = useState(true);
    const accordionRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const accordionInnerContent = accordionRef.current?.querySelector(
            "div.MuiAccordion-region[role='region']"
        );

        if (accordionInnerContent) {
            accordionInnerContent.removeAttribute('role');
        }
    }, []);

    function renderHeading(facet: FacetNormalized) {
        if (
            facet.facetKey === ITEM_PROGRAM_FACET_KEY ||
            facet.facetKey === GRS_PROGRAM_TYPES_FACET_KEY
        ) {
            return ' ';
        }

        if (
            facet.facetKey === ITEM_LOCATION_FACET_KEY ||
            facet.facetKey === GRS_BUY_IN_WAREHOUSE_FACET_KEY
        ) {
            return ' ';
        }
        return facet.label;
    }

    return (
        <Accordion
            title={renderHeading(facet)}
            // @ts-ignore Accordion doesn't export this type
            variant="primary"
            isExpanded={isExpanded}
            onChange={() => setIsExpanded(!isExpanded)}
            uniqueId={facet.facetKey}
            ref={accordionRef}
        >
            {facet.facetKey === ITEM_CATEGORY_FACET_KEY ||
            facet.facetKey === GRS_CATEGORY_INFO_FACET_KEY ? (
                <FilterGroup
                    categoryUrlPath={categoryUrlPath}
                    facet={facet}
                    config={facetConfig}
                    locale={locale}
                    pageType={pageType}
                    categoryId={categoryId}
                    breadcrumb={breadcrumb}
                    searchService={searchService}
                />
            ) : (
                <StyledFieldset aria-labelledby={facet.facetKey}>
                    <FilterGroup
                        facet={facet}
                        config={facetConfig}
                        locale={locale}
                        pageType={pageType}
                        searchService={searchService}
                        categoryId={categoryId}
                    />
                </StyledFieldset>
            )}
        </Accordion>
    );
}
