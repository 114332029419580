'use client';

import { ReactNode } from 'react';

import { useQueryParams } from '@costcolabs/forge-digital-components';

export default function SearchVisibilityToggle({
    children,
    searchEnabled,
    fallback,
}: {
    children: ReactNode;
    searchEnabled: boolean;
    fallback?: ReactNode;
}) {
    const { queryParams: searchParams } = useQueryParams();

    if (searchEnabled || !!searchParams.get('refine')) {
        return <>{children}</>;
    }

    if (fallback) {
        return <>{fallback}</>;
    }

    return null;
}
