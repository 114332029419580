'use client';

import { useEffect, useState } from 'react';

import { useSearchContext } from '@/src/services/search/SearchProvider';
import { AnnouncerModel } from '@costcolabs/forge-components';

export function ResultsAnnouncer({
    announcerText,
}: {
    announcerText?: string;
}) {
    const { searchResult } = useSearchContext();
    const [ariaResultsText, setAriaResultsText] = useState<
        string | undefined
    >();

    useEffect(() => {
        setAriaResultsText(announcerText);
    }, [searchResult?.docs?.length, announcerText]);

    return (
        <AnnouncerModel
            // @ts-expect-error not typed
            announcerText={ariaResultsText}
            callback={setAriaResultsText}
            ariaLive="polite"
            announcerTime={5000}
        />
    );
}
