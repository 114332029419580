'use client';

import { StyledButton } from '../styles';

import { useEffect, useState } from 'react';

import {
    getFacetTagLabel,
    getFilterType,
    getGRSQueryParamValue,
    getSelectGRSFacetsFromUrl,
} from '@/components/SearchResultsFacets/grs.lib';
import { FacetNormalized } from '@/components/SearchResultsFacets/types';
import { getKeyValue } from '@/services/content/business.lib';
import { isSearchResultGRS } from '@/services/search/services/Grs';
import {
    FILTER_REMOVE,
    FILTER_VAL,
    TRACK_TYPE_MULTI_ATTR,
} from '@/src/components/Analytics/constants';
import { FACET_OPTION_SELECTED_FILTER } from '@/src/components/SearchResultsFacets/constants';
import useRefinement from '@/src/hooks/useRefinement';
import { useSearchContext } from '@/src/services/search/SearchProvider';
import type {
    ContentStackEntryDataProps,
    GRSFacetConfigProperties,
} from '@/types/contentStack';
import {
    ColorBlue500,
    SpaceXs,
    SpaceXxs,
} from '@costcolabs/forge-design-tokens';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { XThin } from '@costcolabs/forge-icons';

export function GRSFilters({
    config,
    grsFacetConfig,
}: {
    config: ContentStackEntryDataProps;
    grsFacetConfig: ContentStackEntryDataProps<GRSFacetConfigProperties>;
}) {
    const { searchResult, searchService } = useSearchContext();
    const [selectedFacets, setSelectedFacets] = useState<FacetNormalized[]>([]);
    const { queryParams: searchParams } = useQueryParams();
    const { handleDoRefine } = useRefinement({ searchService });

    useEffect(() => {
        if (!searchResult || !isSearchResultGRS(searchResult)) {
            return undefined;
        }

        const selectFacets = getSelectGRSFacetsFromUrl({
            searchParams,
            searchResult,
            grsFacetConfig,
        });

        setSelectedFacets(selectFacets ?? []);
    }, [grsFacetConfig, searchParams, searchResult]);
    const removeFilterText = getKeyValue('a11y.removefiltertext', config);
    return (
        <>
            {selectedFacets.map(facet => {
                return facet.buckets.map((bucket, index) => {
                    const label = getFacetTagLabel({
                        facet,
                        bucket,
                        config,
                        grsFacetConfig,
                    });

                    const queryParamKey = facet.queryParamKey ?? facet.facetKey;
                    const queryParamValue = getGRSQueryParamValue(
                        facet,
                        bucket
                    );
                    let filterType = getFilterType(facet.facetKey);

                    let refinement = `${queryParamKey}-${queryParamValue}`;

                    return (
                        <Analytics
                            analyticData={{
                                trackedData: [
                                    {
                                        key: FILTER_REMOVE,
                                        value: filterType,
                                    },
                                    {
                                        key: FILTER_VAL,
                                        value: label,
                                    },
                                ],
                                trackType: TRACK_TYPE_MULTI_ATTR,
                            }}
                            key={refinement + '-analytics'}
                        >
                            <StyledButton
                                key={`${refinement}-${index}-analytics`}
                                onClick={() =>
                                    handleDoRefine(facet, bucket, false)
                                }
                                aria-label={`${label}, ${removeFilterText}`}
                                uniqueId={`${FACET_OPTION_SELECTED_FILTER}_${label}`}
                                sx={{
                                    marginBottom: '14px',
                                    padding: `${SpaceXxs} ${SpaceXs}`,
                                }}
                            >
                                {label}
                                <XThin
                                    style={{
                                        color: ColorBlue500,
                                        height: '14px',
                                        paddingLeft: '12px',
                                    }}
                                />
                            </StyledButton>
                        </Analytics>
                    );
                });
            })}
        </>
    );
}
