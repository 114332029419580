'use client';

import { isNonDefaultFacetApplied } from '../SearchResultsFacets/utils';

import React, { ReactNode } from 'react';

import { useQueryParams } from '@costcolabs/forge-digital-components';

export default function FilterAwareVisibilityToggle({
    children,
}: {
    children: ReactNode;
}) {
    const { queryParams: searchParams } = useQueryParams();
    const shouldHideChildren = isNonDefaultFacetApplied(searchParams);

    if (shouldHideChildren) {
        return null;
    }

    return <>{children}</>;
}
