import { z } from 'zod';

import { createEnv } from '@t3-oss/env-nextjs';

// See https://env.t3.gg/docs/nextjs for details pertaining to the code below.
export const env = createEnv({
    server: {
        NODE_ENV: z.string().min(1),

        CONTENTSTACK_BROWSE_API_KEY: z.string().min(1),
        CONTENTSTACK_BROWSE_DELIVERY_TOKEN: z.string().min(1),
        CONTENTSTACK_BROWSE_PREVIEW_TOKEN: z.string().min(1),
        CONTENTSTACK_BROWSE_ENVIRONMENT: z.string().min(1),
        CONTENTSTACK_BROWSE_BRANCH: z.string().min(1),
        CONTENTSTACK_BROWSE_API_HOST: z.string().min(1),
        CONTENTSTACK_BROWSE_APP_HOST: z.string().min(1),
        CONTENTSTACK_BROWSE_PREVIEW_HOST: z.string().min(1),
        CONTENTSTACK_BROWSE_REGION: z.string().min(1),

        CONTENTSTACK_CONFIG_API_KEY: z.string().min(1),
        CONTENTSTACK_CONFIG_DELIVERY_TOKEN: z.string().min(1),
        CONTENTSTACK_CONFIG_ENVIRONMENT: z.string().min(1),
        CONTENTSTACK_CONFIG_BRANCH: z.string().min(1),
        CONTENTSTACK_CONFIG_API_HOST: z.string().min(1),
        CONTENTSTACK_CONFIG_APP_HOST: z.string().min(1),
        CONTENTSTACK_CONFIG_REGION: z.string().min(1),

        CONTENT_ENTRY_ID_FOOTER: z.string().min(1),
        CONTENT_ENTRY_ID_HEADER: z.string().min(1),
        CONTENT_ENTRY_ID_HOMEPAGE: z.string().min(1),
        CONTENT_ENTRY_TYPE_HOMEPAGE: z.string().min(1),
        CONTENT_ENTRY_ID_SITE_CONFIGURATION: z.string().min(1),
        CONTENT_ENTRY_ID_CLIENT_CONFIGURATION: z.string().min(1),
        CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION: z.string().min(1),
        LOGROCKET_CUSTOMERRECORDING_APPID: z.string().min(1),
        LOGROCKET_BUSINESSUSERRECORDING_APPID: z.string().min(1),
        SITE: z.string().min(1),
        INTERNAL_API_BASE_URL: z.string().url().min(1),

        SUPPORTED_LANGUAGES: z.string().min(1),
        DEFAULT_LANGUAGE: z.string().min(1),

        BUILD_NUMBER: z.string().min(1),
        BUILD_BRANCH: z.string().min(1),
        HOST_ENVIRONMENT: z.string().min(1),

        REDIS_SHARED_CACHE: z.string().min(1),
        REDIS_HOST: z.string().optional(),
        REDIS_PORT: z.string().optional(),
        REDIS_PASSWORD: z.string().optional(),
    },
    client: {
        // We dont want to expose any of the server env variables to the client, so leave this empty
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_API_KEY: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_DELIVERY_TOKEN: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_PREVIEW_TOKEN: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_ENVIRONMENT: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_BRANCH: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_API_HOST: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_APP_HOST: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_PREVIEW_HOST: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_REGION: z.string().min(1),

        NEXT_PUBLIC_CONTENTSTACK_CONFIG_API_KEY: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_CONFIG_DELIVERY_TOKEN: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_CONFIG_ENVIRONMENT: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_CONFIG_BRANCH: z.string().min(1),
        NEXT_PUBLIC_CONTENTSTACK_CONFIG_REGION: z.string().min(1),
        NEXT_PUBLIC_CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION: z
            .string()
            .min(1),
        NEXT_PUBLIC_CONTENT_ENTRY_ID_CLIENT_CONFIGURATION: z.string().min(1),

        NEXT_PUBLIC_WAREHOUSE_BASE_URL: z.string().url().min(1),

        NEXT_PUBLIC_LOGROCKET_CUSTOMERRECORDING_APPID: z.string().min(1),
        NEXT_PUBLIC_LOGROCKET_BUSINESSUSERRECORDING_APPID: z.string().min(1),

        NEXT_PUBLIC_LOGOUT_URLS: z.string().min(1),
    },
    runtimeEnv: {
        NODE_ENV: process.env.NODE_ENV,

        SITE: process.env.SITE,
        CONTENT_ENTRY_ID_SITE_CONFIGURATION:
            process.env.CONTENT_ENTRY_ID_SITE_CONFIGURATION,
        CONTENTSTACK_BROWSE_API_KEY: process.env.CONTENTSTACK_BROWSE_API_KEY,
        CONTENTSTACK_BROWSE_DELIVERY_TOKEN:
            process.env.CONTENTSTACK_BROWSE_DELIVERY_TOKEN,
        CONTENTSTACK_BROWSE_PREVIEW_TOKEN:
            process.env.CONTENTSTACK_BROWSE_PREVIEW_TOKEN,
        CONTENTSTACK_BROWSE_ENVIRONMENT:
            process.env.CONTENTSTACK_BROWSE_ENVIRONMENT,
        CONTENTSTACK_BROWSE_BRANCH: process.env.CONTENTSTACK_BROWSE_BRANCH,
        CONTENTSTACK_BROWSE_API_HOST: process.env.CONTENTSTACK_BROWSE_API_HOST,
        CONTENTSTACK_BROWSE_APP_HOST: process.env.CONTENTSTACK_BROWSE_APP_HOST,
        CONTENTSTACK_BROWSE_PREVIEW_HOST:
            process.env.CONTENTSTACK_BROWSE_PREVIEW_HOST,
        CONTENTSTACK_BROWSE_REGION: process.env.CONTENTSTACK_BROWSE_REGION,
        CONTENTSTACK_CONFIG_API_KEY: process.env.CONTENTSTACK_CONFIG_API_KEY,
        CONTENTSTACK_CONFIG_DELIVERY_TOKEN:
            process.env.CONTENTSTACK_CONFIG_DELIVERY_TOKEN,
        CONTENTSTACK_CONFIG_ENVIRONMENT:
            process.env.CONTENTSTACK_CONFIG_ENVIRONMENT,
        CONTENTSTACK_CONFIG_BRANCH: process.env.CONTENTSTACK_CONFIG_BRANCH,
        CONTENTSTACK_CONFIG_API_HOST: process.env.CONTENTSTACK_CONFIG_API_HOST,
        CONTENTSTACK_CONFIG_APP_HOST: process.env.CONTENTSTACK_CONFIG_APP_HOST,
        CONTENTSTACK_CONFIG_REGION: process.env.CONTENTSTACK_CONFIG_REGION,
        CONTENT_ENTRY_ID_FOOTER: process.env.CONTENT_ENTRY_ID_FOOTER,
        CONTENT_ENTRY_ID_HEADER: process.env.CONTENT_ENTRY_ID_HEADER,
        CONTENT_ENTRY_ID_HOMEPAGE: process.env.CONTENT_ENTRY_ID_HOMEPAGE,
        CONTENT_ENTRY_TYPE_HOMEPAGE: process.env.CONTENT_ENTRY_TYPE_HOMEPAGE,
        CONTENT_ENTRY_ID_CLIENT_CONFIGURATION:
            process.env.CONTENT_ENTRY_ID_CLIENT_CONFIGURATION,
        CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION:
            process.env.CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION,
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_API_KEY:
            process.env.NEXT_PUBLIC_CONTENTSTACK_BROWSE_API_KEY,
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_DELIVERY_TOKEN:
            process.env.NEXT_PUBLIC_CONTENTSTACK_BROWSE_DELIVERY_TOKEN,
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_PREVIEW_TOKEN:
            process.env.NEXT_PUBLIC_CONTENTSTACK_BROWSE_PREVIEW_TOKEN,
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_ENVIRONMENT:
            process.env.NEXT_PUBLIC_CONTENTSTACK_BROWSE_ENVIRONMENT,
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_BRANCH:
            process.env.NEXT_PUBLIC_CONTENTSTACK_BROWSE_BRANCH,
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_API_HOST:
            process.env.NEXT_PUBLIC_CONTENTSTACK_BROWSE_API_HOST,
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_APP_HOST:
            process.env.NEXT_PUBLIC_CONTENTSTACK_BROWSE_APP_HOST,
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_PREVIEW_HOST:
            process.env.NEXT_PUBLIC_CONTENTSTACK_BROWSE_PREVIEW_HOST,
        NEXT_PUBLIC_CONTENTSTACK_BROWSE_REGION:
            process.env.NEXT_PUBLIC_CONTENTSTACK_BROWSE_REGION,
        NEXT_PUBLIC_CONTENTSTACK_CONFIG_API_KEY:
            process.env.NEXT_PUBLIC_CONTENTSTACK_CONFIG_API_KEY,
        NEXT_PUBLIC_CONTENTSTACK_CONFIG_DELIVERY_TOKEN:
            process.env.NEXT_PUBLIC_CONTENTSTACK_CONFIG_DELIVERY_TOKEN,
        NEXT_PUBLIC_CONTENTSTACK_CONFIG_ENVIRONMENT:
            process.env.NEXT_PUBLIC_CONTENTSTACK_CONFIG_ENVIRONMENT,
        NEXT_PUBLIC_CONTENTSTACK_CONFIG_BRANCH:
            process.env.NEXT_PUBLIC_CONTENTSTACK_CONFIG_BRANCH,
        NEXT_PUBLIC_CONTENTSTACK_CONFIG_REGION:
            process.env.NEXT_PUBLIC_CONTENTSTACK_CONFIG_REGION,
        NEXT_PUBLIC_CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION:
            process.env
                .NEXT_PUBLIC_CONTENT_ENTRY_ENVIRONMENT_CLIENT_CONFIGURATION,
        NEXT_PUBLIC_CONTENT_ENTRY_ID_CLIENT_CONFIGURATION:
            process.env.NEXT_PUBLIC_CONTENT_ENTRY_ID_CLIENT_CONFIGURATION,
        NEXT_PUBLIC_WAREHOUSE_BASE_URL:
            process.env.NEXT_PUBLIC_WAREHOUSE_BASE_URL,
        NEXT_PUBLIC_LOGOUT_URLS: process.env.NEXT_PUBLIC_LOGOUT_URLS,
        INTERNAL_API_BASE_URL: process.env.INTERNAL_API_BASE_URL,
        LOGROCKET_CUSTOMERRECORDING_APPID:
            process.env.LOGROCKET_CUSTOMERRECORDING_APPID,
        LOGROCKET_BUSINESSUSERRECORDING_APPID:
            process.env.LOGROCKET_BUSINESSUSERRECORDING_APPID,
        NEXT_PUBLIC_LOGROCKET_CUSTOMERRECORDING_APPID:
            process.env.NEXT_PUBLIC_LOGROCKET_CUSTOMERRECORDING_APPID,
        NEXT_PUBLIC_LOGROCKET_BUSINESSUSERRECORDING_APPID:
            process.env.NEXT_PUBLIC_LOGROCKET_BUSINESSUSERRECORDING_APPID,
        SUPPORTED_LANGUAGES: process.env.SUPPORTED_LANGUAGES,
        DEFAULT_LANGUAGE: process.env.DEFAULT_LANGUAGE,
        BUILD_NUMBER: process.env.BUILD_NUMBER,
        BUILD_BRANCH: process.env.BUILD_BRANCH,
        HOST_ENVIRONMENT: process.env.HOST_ENVIRONMENT,
        REDIS_SHARED_CACHE: process.env.REDIS_SHARED_CACHE,
        REDIS_HOST: process.env.REDIS_HOST,
        REDIS_PORT: process.env.REDIS_PORT,
        REDIS_PASSWORD: process.env.REDIS_PASSWORD,
    },
});
