import { FACET_CS_DEV_KEY } from '@/components/SearchResultsFacets/constants';
import { ContentType } from '@/constants/contentStack';
import { CONTENT_STACK_DEV_KEY } from '@/constants/index';
import {
    findByEntryId,
    getConfigurationSettingByDeveloperKey,
} from '@/services/content/business.lib';
import { getConfigurationSettingByDevelopmentKey } from '@/services/content/config.lib';
import type {
    AdBuilderReference,
    AdSetCostcoReference,
    AdSetThirdPartyReference,
    AdTargetPlacementBlockReference,
    BulletDetailReference,
    ButtonSetReference,
    CategoryLandingPage,
    ComposerData,
    ContentGroup,
    ContentStackEntryDataProps,
    CriteoInGridBlockReference,
    CustomRichTextBlockReference,
    FeatureHighlightCardV2Reference,
    GRSFacetConfigProperties,
    GrsConfig,
    MarkdownReference,
    ProgramCardReference,
    SearchComposerData,
    SearchComposerV2Data,
    SearchPageConfigProperties,
    SearchRuleEntry,
    SearchServiceConfig,
    TabsBlockReference,
    TextBuilderReference,
    TextSectionBlock,
    TieredOfferCardReference,
} from '@/types/contentStack';
import type { LocaleProps } from '@costcolabs/forge-digital-components';

import {
    FIRST_ITEM_INDEX_TOKEN,
    LAST_ITEM_INDEX_TOKEN,
    TOTAL_ITEMS_TOKEN,
} from './constants';

export function isAdBuilderEntry(
    entry: ContentGroup
): entry is AdBuilderReference {
    return 'ad_builder_block' in entry;
}

export function isAdSetCostcoEntry(
    entry: ContentGroup
): entry is AdSetCostcoReference {
    return 'ad_set_costco_block' in entry;
}

export function isAdSetThirdPartyEntry(
    entry: ContentGroup
): entry is AdSetThirdPartyReference {
    return 'ad_set_3rd_party_block' in entry;
}

export function isCriteoInGridBannerEntry(
    entry: ContentGroup
): entry is CriteoInGridBlockReference {
    return 'criteo_ingrid_ad_block' in entry;
}

export function isCustomRichTextBlock(
    entry: ContentGroup
): entry is CustomRichTextBlockReference {
    return 'custom_rich_text_block' in entry;
}

export function isButtonEntry(
    entry: ContentGroup
): entry is ButtonSetReference {
    return 'button_set_block' in entry;
}

export function isMarkdownEntry(
    entry: ContentGroup
): entry is MarkdownReference {
    return 'markdown_block' in entry;
}

export function isBulletDetailEntry(
    entry: ContentGroup
): entry is BulletDetailReference {
    return 'bullet_detail_card_block' in entry;
}

export function isProgramCardEntry(
    entry: ContentGroup
): entry is ProgramCardReference {
    return 'program_card_block' in entry;
}

export function isFeatureHighlightCardV2Entry(
    entry: ContentGroup
): entry is FeatureHighlightCardV2Reference {
    return 'feature_highlight_card_v2_block' in entry;
}

export function isTieredOfferCardEntry(
    entry: ContentGroup
): entry is TieredOfferCardReference {
    return 'tiered_offer_card_block' in entry;
}

export function isTextBuilderEntry(
    entry: ContentGroup
): entry is TextBuilderReference {
    return 'text_builder_block' in entry;
}

export function isTextSectionEntry(
    entry: ContentGroup
): entry is TextSectionBlock {
    return 'text_section_block' in entry;
}

export function getDisplayGroupId(entry: ContentGroup) {
    if (isAdBuilderEntry(entry)) {
        return entry.ad_builder_block.ad_builder_ref?.[0]?.uid;
    }
    if (isAdSetCostcoEntry(entry)) {
        return entry.ad_set_costco_block.ad_set_costco_ref?.[0]?.uid;
    }
    if (isAdSetThirdPartyEntry(entry)) {
        return entry.ad_set_3rd_party_block.ad_set_3rd_party_ref?.[0]?.uid;
    }
    if (isCriteoInGridBannerEntry(entry)) {
        return entry.criteo_ingrid_ad_block?.ingrid_type_ref;
    }
    if (isBulletDetailEntry(entry)) {
        return entry.bullet_detail_card_block.bullet_detail_card_ref?.[0]?.uid;
    }
    if (isButtonEntry(entry)) {
        return entry.button_set_block.button_set_ref?.[0]?.uid;
    }
    if (isCustomRichTextBlock(entry)) {
        return entry.custom_rich_text_block.custom_rich_text_ref?.[0]?.uid;
    }
    if (isFeatureHighlightCardV2Entry(entry)) {
        return entry.feature_highlight_card_v2_block
            .feature_highlight_card_v2_ref?.[0]?.uid;
    }
    if (isTieredOfferCardEntry(entry)) {
        return entry.tiered_offer_card_block.tiered_offer_card_ref?.[0]?.uid;
    }
    if (isMarkdownEntry(entry)) {
        return entry.markdown_block._metadata?.uid;
    }
    if (isProgramCardEntry(entry)) {
        return entry.program_card_block.program_card_ref?.[0]?.uid;
    }
    if (isTextBuilderEntry(entry)) {
        return entry.text_builder_block.text_builder_ref?.[0]?.uid;
    }
    if (isTabsBlockEntry(entry)) {
        return entry.tabs_block.tabs_ref?.[0]?.uid;
    }
    if (isTextSectionEntry(entry)) {
        return entry.text_section_block._metadata.uid;
    }
}

export function getDisplayGroupContentType(entry: AdSetThirdPartyReference) {
    if (isAdSetThirdPartyEntry(entry)) {
        return entry.ad_set_3rd_party_block?.ad_set_3rd_party_ref?.[0]
            ?._content_type_uid;
    }
}

export function isTabsBlockEntry(
    entry: ContentGroup
): entry is TabsBlockReference {
    if (!entry) {
        return false;
    }
    return 'tabs_block' in entry;
}

export function isSearchRuleEntry(
    entry?: SearchRuleEntry | CategoryLandingPage
): entry is SearchRuleEntry {
    if (!entry) {
        return false;
    }

    return 'search_page_title' in entry;
}

export function isCategoryLandingPageEntry(
    entry?: SearchRuleEntry | CategoryLandingPage
): entry is CategoryLandingPage {
    if (!entry) {
        return false;
    }

    return 'page_id' in entry && 'category_id' in entry;
}

export function populateProductCount(
    template: string | undefined,
    data: {
        firstDisplayedItemIndex: number;
        lastDisplayedItemIndex: number;
        totalItems: number;
    }
) {
    if (!template) {
        return null;
    }
    return template
        .replace(
            FIRST_ITEM_INDEX_TOKEN,
            data.firstDisplayedItemIndex.toLocaleString()
        )
        .replace(
            LAST_ITEM_INDEX_TOKEN,
            data.lastDisplayedItemIndex.toLocaleString()
        )
        .replace(TOTAL_ITEMS_TOKEN, data.totalItems.toLocaleString());
}

export function getFirstItemIndex(currentPage: number, resultsPerPage: number) {
    return currentPage * resultsPerPage - resultsPerPage + 1;
}

export function getLastItemIndex(
    currentPage: number,
    resultsPerPage: number,
    totalDocs: number
) {
    let lastDisplayedItemIndex = currentPage * resultsPerPage;

    if (lastDisplayedItemIndex > totalDocs) {
        lastDisplayedItemIndex = totalDocs;
    }

    return lastDisplayedItemIndex;
}

export function isTopLevelContentGroup(
    entryData: SearchComposerData | SearchComposerV2Data | CategoryLandingPage
): entryData is SearchComposerV2Data | CategoryLandingPage {
    // If it has the additional property of ad_placements, its an v1 search composer
    return !(
        entryData.above_search_results.hasOwnProperty('ad_placements') ||
        entryData.below_search_results.hasOwnProperty('ad_placements') ||
        entryData.ingrid_search_results.hasOwnProperty('ad_placements')
    );
}

/**
 * This takes in multiple page structures from ContentStack and returns
 * a common structure that can be used to render the page ads
 */
export function getComposerDataFromEntry(
    entryData?: SearchComposerData | SearchComposerV2Data | CategoryLandingPage
): ComposerData {
    if (!entryData) {
        return {
            aboveGridPlacements: [],
            belowGridPlacements: [],
            inGridPlacements: [],
        };
    }

    function extractContentGroupArray(
        group:
            | 'above_search_results'
            | 'below_search_results'
            | 'ingrid_search_results'
    ): ContentGroup[] {
        if (!entryData) {
            return [];
        }

        if (isTopLevelContentGroup(entryData)) {
            // SearchComposerV2 & CategoryLandingPages
            return entryData[group];
        }
        // SearchComposer V1
        return entryData[group]?.ad_placements || [];
    }

    // Support for v1 and v2 SearchComposer as well as CLP (same as V2)
    const aboveGridPlacements = extractContentGroupArray(
        'above_search_results'
    );

    const belowGridPlacements = extractContentGroupArray(
        'below_search_results'
    );

    const inGridPlacements = extractContentGroupArray('ingrid_search_results');

    return { aboveGridPlacements, belowGridPlacements, inGridPlacements };
}

export function isAdditionalAdsZone(
    adPlacement: ContentGroup
): adPlacement is AdTargetPlacementBlockReference {
    return 'ad_targeting_placement_block' in adPlacement;
}

// Takes the baseEntry object, and looks in each of the three content group arrays for targeted ad zones,
// if found, it will inject the appliedEntries ad for that content group into the zone
export function mergeComposerData(
    baseEntry: ComposerData,
    appliedEntry?: ComposerData
) {
    if (!appliedEntry) {
        return baseEntry;
    }

    const localAppliedEntry = structuredClone(appliedEntry);

    const mapSpecificAdsIntoTargetZones =
        (group: keyof ComposerData) => (adPlacement: ContentGroup) => {
            // It's not a ad zone, or it is not set to enabled, keep it as is
            if (
                !isAdditionalAdsZone(adPlacement) ||
                !adPlacement.ad_targeting_placement_block.enable_targeted_ads
            ) {
                return adPlacement;
            }
            // Returning the splice lets us clear out the ads from the applied rule
            // so we don't worry about adding it to multiple ad zones
            return localAppliedEntry[group].splice(
                0,
                localAppliedEntry[group].length
            );
        };

    // Iterate over each ad placement to look for the first targeting placement, and inject
    // all above grid ad placements from the applied rule into the base rule
    const aboveGridPlacements = baseEntry.aboveGridPlacements
        .map(mapSpecificAdsIntoTargetZones('aboveGridPlacements'))
        .flat();

    const belowGridPlacements = baseEntry.belowGridPlacements
        .map(mapSpecificAdsIntoTargetZones('belowGridPlacements'))
        .flat();

    const inGridPlacements = baseEntry.inGridPlacements
        .map(mapSpecificAdsIntoTargetZones('inGridPlacements'))
        .flat();

    return { aboveGridPlacements, belowGridPlacements, inGridPlacements };
}

function _createCriteoSearchFilterString(
    refine: string,
    regx: RegExp,
    filterContent: string,
    {
        modifyMatch,
        modifyFilters,
    }: {
        modifyMatch?: (s?: string) => string | undefined;
        modifyFilters?: (s?: string[]) => string[];
    } = {}
) {
    let matches = [...refine.matchAll(regx)];

    let filters = matches.reduce((filters, match) => {
        let matchingValue = match?.[1];

        if (modifyMatch) {
            matchingValue = modifyMatch(matchingValue);
        }

        if (matchingValue) {
            filters.push(matchingValue);
        }

        return filters;
    }, [] as string[]);

    if (modifyFilters) {
        filters = modifyFilters(filters);
    }

    const filterString =
        filters.length > 0
            ? `(${filterContent}${filters.join(',')})`
            : undefined;

    return filterString;
}

export function createCriteoSearchFiltersString(
    refine: string | string[] = '',
    categoryId?: string
) {
    if (Array.isArray(refine)) {
        refine = refine[0] as string;
    }
    let ratingFilterString = _createCriteoSearchFilterString(
        refine,
        /item_rating_value-([0-9])\s*&*\s*[a-zA-Z]*/gi,
        'ratings,ge,',
        {
            modifyFilters: (matchingValues?: string[]) => {
                if (!matchingValues?.length) {
                    return [];
                }

                return [
                    matchingValues?.reduce((smallestValue, value) => {
                        if (
                            !smallestValue ||
                            parseInt(value) < parseInt(smallestValue)
                        ) {
                            return value;
                        }

                        return smallestValue;
                    }),
                ];
            },
        }
    );

    let priceFilterString = _createCriteoSearchFilterString(
        refine,
        /item_location_pricing_salePrice-\[([0-9]+\s*TO\s*[0-9]+)\]/gi,
        'price_range,in,',
        {
            modifyMatch: (matchingValue?: string) => {
                return matchingValue?.replace(/\s*TO\s*/, '-');
            },
        }
    );

    let brandFilterString = _createCriteoSearchFilterString(
        refine,
        /Brand_attr-((?:(?!\|\|).)+)(\|\||$)/gi,
        'brand,in,'
    );

    let categoryFilterString = categoryId
        ? `(category,in,${categoryId})`
        : undefined;

    return [
        ratingFilterString,
        priceFilterString,
        brandFilterString,
        categoryFilterString,
    ]
        .filter(s => !!s)
        .join(', ');
}

export async function getCLPComposerDataFromEntry(
    entryId: string,
    lang: LocaleProps
): Promise<ComposerData | undefined> {
    const categoryLandingPageEntry = await findByEntryId<
        CategoryLandingPage | undefined
    >(lang, ContentType.CategoryLandingPage, entryId);

    return getComposerDataFromEntry(categoryLandingPageEntry);
}

export async function getServerSideConfig({
    lang,
    enableLivePreview,
}: {
    lang: LocaleProps;
    enableLivePreview?: boolean;
}) {
    const config = await getConfigurationSettingByDeveloperKey<
        ContentStackEntryDataProps<SearchPageConfigProperties>
    >(ContentType.PageSettings, CONTENT_STACK_DEV_KEY, lang);
    const facetConfig =
        await getConfigurationSettingByDeveloperKey<ContentStackEntryDataProps>(
            ContentType.ModuleSettings,
            FACET_CS_DEV_KEY,
            lang,
            enableLivePreview
        );
    const serviceConfig =
        await getConfigurationSettingByDevelopmentKey<SearchServiceConfig>(
            ContentType.ServiceConfiguration,
            config.properties[0]!.configKey,
            enableLivePreview
        );

    return { config, facetConfig, serviceConfig };
}

export async function getServerSideGrsConfig(): Promise<GrsConfig> {
    const grsConfig = await getConfigurationSettingByDevelopmentKey<GrsConfig>(
        ContentType.ServiceConfiguration,
        'keyword_search_api_service_config'
    );
    return grsConfig;
}
export async function getGrsFacetConfig(
    locale: LocaleProps
): Promise<ContentStackEntryDataProps<GRSFacetConfigProperties>> {
    const grsFacetConfig = await getConfigurationSettingByDeveloperKey<
        ContentStackEntryDataProps<GRSFacetConfigProperties>
    >(ContentType.ModuleSettings, 'grs_search_facets', locale);
    return grsFacetConfig;
}

// Category landing pages could have search enabled or not
export function isSearchEnabled(
    categoryLandingPage: CategoryLandingPage | SearchRuleEntry | undefined
): boolean {
    if (!categoryLandingPage) {
        return true;
    }

    if ('enable_search' in categoryLandingPage) {
        return categoryLandingPage.enable_search;
    }
    return true;
}

// Category landing pages could have search filters (left column) enabled or not
export function isFilterEnabled(
    categoryLandingPage: CategoryLandingPage | SearchRuleEntry | undefined
): boolean {
    if (!categoryLandingPage) {
        return true;
    }

    if ('enable_search_filter' in categoryLandingPage) {
        //  Enable Search is a parent of search filter, so if that's disabled, search filter is disabled
        if (categoryLandingPage.enable_search === false) {
            return false;
        }

        return categoryLandingPage.enable_search_filter;
    }

    return true;
}
