'use client';

import {
    ITEM_CATEGORY_FACET_KEY,
    ITEM_LOCATION_FACET_KEY,
    ITEM_LOCATION_PRICING_FACET_KEY,
    ITEM_PROGRAM_FACET_KEY,
    ITEM_RATING_FACET_KEY,
} from '@/constants/index';
import { getKeyValue } from '@/services/content/business.lib';
import { SearchServiceProps } from '@/services/search/types';
import {
    CategoryFilterView,
    CategoryGRSFilterView,
    DefaultFilterView,
    LocationFilterView,
    PricingFilterView,
    ProgramFilterView,
    RatingFilterView,
} from '@/src/components/FilterGroup/views';
import {
    GRS_BUY_IN_WAREHOUSE_FACET_KEY,
    GRS_CATEGORY_INFO_FACET_KEY,
    GRS_CA_PRICE_FACET_KEY,
    GRS_PROGRAM_TYPES_FACET_KEY,
    GRS_RATING_FACET_KEY,
    GRS_SHOW_OUT_OF_STOCK_FACET_KEY,
    GRS_US_PRICE_FACET_KEY,
    GRS_WAREHOUSE_PICKUP_FACET_KEY,
} from '@/src/components/SearchResultsFacets/grs.constants';
import {
    BreadcrumbNormalized,
    FacetNormalized,
} from '@/src/components/SearchResultsFacets/types';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { PageType } from '@/types/pages/search';
import type { LocaleProps } from '@costcolabs/forge-digital-components';

export function FilterGroup({
    categoryUrlPath,
    facet,
    config,
    locale,
    pageType,
    categoryId,
    breadcrumb,
    searchService,
}: {
    categoryUrlPath?: string | undefined;
    facet: FacetNormalized;
    config: ContentStackEntryDataProps;
    locale: LocaleProps;
    pageType: PageType;
    breadcrumb?: BreadcrumbNormalized;
    searchService: SearchServiceProps;
    categoryId?: string;
}) {
    const resultsText = getKeyValue('a11y.resultstext', config);
    switch (facet.facetKey) {
        case ITEM_CATEGORY_FACET_KEY:
            return (
                <CategoryFilterView
                    categoryUrlPath={categoryUrlPath}
                    facet={facet}
                    resultsText={resultsText}
                    config={config}
                    pageType={pageType}
                    categoryId={categoryId}
                    breadcrumb={breadcrumb}
                />
            );

        case GRS_CATEGORY_INFO_FACET_KEY:
            return (
                <CategoryGRSFilterView
                    categoryUrlPath={categoryUrlPath}
                    facet={facet}
                    resultsText={resultsText}
                    config={config}
                    categoryId={categoryId}
                />
            );

        case ITEM_PROGRAM_FACET_KEY:
        case GRS_PROGRAM_TYPES_FACET_KEY:
        case GRS_BUY_IN_WAREHOUSE_FACET_KEY:
        case GRS_WAREHOUSE_PICKUP_FACET_KEY:
            return (
                <ProgramFilterView
                    facet={facet}
                    config={config}
                    resultsText={resultsText}
                    locale={locale}
                    searchService={searchService}
                />
            );

        case ITEM_LOCATION_FACET_KEY:
        case GRS_SHOW_OUT_OF_STOCK_FACET_KEY:
            return (
                <LocationFilterView
                    facet={facet}
                    config={config}
                    resultsText={resultsText}
                    searchService={searchService}
                />
            );

        case ITEM_LOCATION_PRICING_FACET_KEY:
        case GRS_US_PRICE_FACET_KEY:
        case GRS_CA_PRICE_FACET_KEY:
            return (
                <PricingFilterView
                    facet={facet}
                    resultsText={resultsText}
                    config={config}
                    searchService={searchService}
                />
            );

        case ITEM_RATING_FACET_KEY:
        case GRS_RATING_FACET_KEY:
            return (
                <RatingFilterView
                    config={config}
                    facet={facet}
                    resultsText={resultsText}
                    searchService={searchService}
                />
            );

        default:
            return (
                <DefaultFilterView
                    config={config}
                    facet={facet}
                    resultsText={resultsText}
                    searchService={searchService}
                />
            );
    }
}
