import {
    ColorCostcoBlue,
    FontWeightDefault,
} from '@costcolabs/forge-design-tokens';

export const DepartmentListTitleStyle = {
    margin: '30px 0 5px 0',
    fontWeight: FontWeightDefault,
};

export const DepartmentListStyle = {
    columns: {
        lg: '4',
        md: '4',
        xs: '2',
    },
    columnGap: {
        lg: '5%',
    },
    listStyle: 'none',
    padding: '0',
    margin: '0 0 20px 0',
};

export const DepartmentListItemStyle = {
    padding: '2px 0',
};

export const DepartmentLinkStyle = {
    textDecoration: 'none',
    color: ColorCostcoBlue,
};
