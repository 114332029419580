'use client';

import { ReactNode } from 'react';

import { FilterGroup } from '@/components/FilterGroup';
import { ProgramEligibilityFilterGroup } from '@/components/FilterGroup/ProgramEligibility';
import { ProgramEligibilityFilterGroupV2 } from '@/components/FilterGroup/ProgramEligibilityV2';
import { FacetsAccordion } from '@/components/FilterGroup/views';
import {
    ITEM_LOCATION_BOPIM_KEY,
    ITEM_LOCATION_BOPIW,
    ITEM_LOCATION_FACET_KEY,
    ITEM_PROGRAM_FACET_KEY,
} from '@/constants/index';
import { isSearchResultGRS } from '@/services/search/services/Grs';
import { isSearchResultLW } from '@/services/search/services/LucidWorks';
import { ResultsAnnouncer } from '@/src/containers/main/ResultsAnnouncer';
import { getKeyValue } from '@/src/services/content/business.lib';
import { useSearchContext } from '@/src/services/search/SearchProvider';
import {
    ContentStackEntryDataProps,
    GRSFacetConfigProperties,
} from '@/src/types/contentStack';
import { PageType } from '@/types/pages/search';
import { Divider } from '@costcolabs/forge-components';
import type { LocaleProps } from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';

import { A11Y_FILTER_DESCRIBED_BY, A11Y_FILTER_KEY } from './constants';
import {
    GRS_BUY_IN_WAREHOUSE_FACET_KEY,
    GRS_PROGRAM_TYPES_FACET_KEY,
    GRS_SHOW_OUT_OF_STOCK_FACET_KEY,
    GRS_WAREHOUSE_PICKUP_FACET_KEY,
} from './grs.constants';
import { normalizeGRSFacets, remapGRSFacetDisplayOrder } from './grs.lib';
import { FilterLoadingView } from './loading.view';
import type { FacetNormalized } from './types';
import { remapFacetDisplayOrder, sortFacets } from './utils';

/**
 * WWarehouseSelectorComponent & DeliveryLocationSelectorComponent must be rendered
 * server side, so they need to be passed in as props to this component.
 */
export default function SearchResultsFacetsClient({
    categoryUrlPath,
    facetConfig,
    grsFacetConfig,
    WarehouseSelectorComponent,
    DeliveryLocationSelectorComponent,
    locale,
    pageType,
    categoryId,
}: {
    categoryUrlPath: string | undefined;
    facetConfig: ContentStackEntryDataProps;
    grsFacetConfig:
        | ContentStackEntryDataProps<GRSFacetConfigProperties>
        | undefined;
    WarehouseSelectorComponent: ReactNode;
    DeliveryLocationSelectorComponent: ReactNode;
    locale: LocaleProps;
    pageType: PageType;
    categoryId?: string;
}) {
    const { searchResult, isSearchResultsLoading, searchService } =
        useSearchContext();

    if (isSearchResultsLoading) {
        return <FilterLoadingView />;
    }

    if (!searchResult) {
        return null;
    }

    const searchResultLW =
        searchService === 'lucidworks' &&
        isSearchResultLW(searchResult) &&
        searchResult;
    const searchResultGRS =
        searchService === 'grs' &&
        isSearchResultGRS(searchResult) &&
        searchResult;

    const normalizedLWFacets = searchResultLW && searchResultLW.facets;

    const normalizedGRSFacets =
        searchResultGRS &&
        grsFacetConfig &&
        normalizeGRSFacets(
            searchResultGRS?.searchResult.facets,
            grsFacetConfig
        );

    return (
        <>
            <Box id={A11Y_FILTER_DESCRIBED_BY} sx={{ display: 'none' }}>
                {getKeyValue(A11Y_FILTER_KEY, facetConfig)}
            </Box>
            <ResultsAnnouncer
                announcerText={getKeyValue('a11y.newresults', facetConfig)}
            />
            {normalizedLWFacets &&
                normalizedLWFacets
                    ?.map(remapFacetDisplayOrder)
                    .sort(sortFacets)
                    .map((facet: FacetNormalized) => {
                        switch (facet.facetKey.toLowerCase()) {
                            // Hide Unavailable Items
                            case ITEM_LOCATION_FACET_KEY:
                                return (
                                    <Box key={facet.facetKey}>
                                        <Divider />
                                        <FilterGroup
                                            facet={facet}
                                            config={facetConfig}
                                            locale={locale}
                                            pageType={pageType}
                                            searchService={searchService}
                                        />
                                    </Box>
                                );
                            // Program Eligibility
                            case ITEM_PROGRAM_FACET_KEY:
                                return (
                                    <ProgramEligibilityFilterGroup
                                        key={facet.facetKey}
                                        facet={facet}
                                        config={facetConfig}
                                        WarehouseSelectorComponent={
                                            WarehouseSelectorComponent
                                        }
                                        DeliveryLocationSelectorComponent={
                                            DeliveryLocationSelectorComponent
                                        }
                                        locale={locale}
                                        pageType={pageType}
                                        bopimFacet={
                                            searchResultLW.facets?.filter(
                                                facet =>
                                                    facet.facetKey ===
                                                    ITEM_LOCATION_BOPIM_KEY
                                            )[0] as FacetNormalized
                                        }
                                        searchService={searchService}
                                    />
                                );

                            case ITEM_LOCATION_BOPIW:
                                return (
                                    <FilterGroup
                                        key={facet.facetKey}
                                        facet={facet}
                                        config={facetConfig}
                                        locale={locale}
                                        pageType={pageType}
                                        searchService={searchService}
                                        categoryId={categoryId}
                                    />
                                );

                            case ITEM_LOCATION_BOPIM_KEY:
                                return null;

                            default:
                                // Even if the buckets array is empty, if it is returned we will pass it on.
                                // This is used in such cases like the Category filter list where we inject breadcrumbs
                                // into the filter even though the array comes back empty
                                if (facet.buckets) {
                                    return (
                                        <FacetsAccordion
                                            categoryUrlPath={categoryUrlPath}
                                            key={facet.facetKey}
                                            facet={facet}
                                            facetConfig={facetConfig}
                                            locale={locale}
                                            pageType={pageType}
                                            breadcrumb={
                                                searchResultLW.breadcrumb
                                            }
                                            searchService={searchService}
                                            categoryId={categoryId}
                                        />
                                    );
                                }
                                return null;
                        }
                    })}

            {normalizedGRSFacets &&
                normalizedGRSFacets
                    ?.map(remapGRSFacetDisplayOrder)
                    .sort(sortFacets)
                    .map((facet: FacetNormalized) => {
                        switch (facet.facetKey.toLowerCase()) {
                            // Hide Unavailable Items
                            case GRS_SHOW_OUT_OF_STOCK_FACET_KEY:
                                return (
                                    <Box key={facet.facetKey}>
                                        <Divider />
                                        <FilterGroup
                                            facet={facet}
                                            config={facetConfig}
                                            locale={locale}
                                            pageType={pageType}
                                            searchService={searchService}
                                        />
                                    </Box>
                                );
                            // Program Eligibility
                            case GRS_PROGRAM_TYPES_FACET_KEY:
                                return (
                                    <ProgramEligibilityFilterGroupV2
                                        key={facet.facetKey}
                                        facet={facet}
                                        config={facetConfig}
                                        grsFacetConfig={grsFacetConfig}
                                        WarehouseSelectorComponent={
                                            WarehouseSelectorComponent
                                        }
                                        DeliveryLocationSelectorComponent={
                                            DeliveryLocationSelectorComponent
                                        }
                                        locale={locale}
                                        pageType={pageType}
                                        bopiwFacet={
                                            normalizedGRSFacets?.filter(
                                                (facet: FacetNormalized) =>
                                                    facet.facetKey ===
                                                    GRS_WAREHOUSE_PICKUP_FACET_KEY
                                            )[0]
                                        }
                                        buyInWarehouseFacet={
                                            normalizedGRSFacets?.filter(
                                                (facet: FacetNormalized) =>
                                                    facet.facetKey ===
                                                    GRS_BUY_IN_WAREHOUSE_FACET_KEY
                                            )[0]
                                        }
                                        searchService={searchService}
                                    />
                                );

                            case GRS_BUY_IN_WAREHOUSE_FACET_KEY:
                            case GRS_WAREHOUSE_PICKUP_FACET_KEY:
                                return null;

                            default:
                                if (facet.buckets.length > 0) {
                                    return (
                                        <FacetsAccordion
                                            categoryUrlPath={categoryUrlPath}
                                            categoryId={categoryId}
                                            key={facet.facetKey}
                                            facet={facet}
                                            facetConfig={facetConfig}
                                            locale={locale}
                                            pageType={pageType}
                                            searchService={searchService}
                                        />
                                    );
                                }
                                return null;
                        }
                    })}
        </>
    );
}
