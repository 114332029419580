'use client';

import { FILTER_TYPE_PRICE } from '../Analytics/constants';

import {
    fullyDecodeURI,
    parseRefineParamToLabels,
} from '@/components/SearchResultsFacets/utils';
import { getKeyValue } from '@/services/content/business.lib';
import { useSearchContext } from '@/services/search/SearchProvider';
import {
    FIRST_ITEM_INDEX_TOKEN,
    LAST_ITEM_INDEX_TOKEN,
    PRODUCT_COUNT_LABEL_KEY,
    TOTAL_ITEMS_TOKEN,
} from '@/src/containers/main/constants';
import {
    getFirstItemIndex,
    getLastItemIndex,
} from '@/src/containers/main/utils';
import type {
    CategoryLandingPage,
    ContentStackEntryDataProps,
    SearchRuleEntry,
} from '@/types/contentStack';
import { Skeleton } from '@costcolabs/forge-components';
import { useQueryParams } from '@costcolabs/forge-digital-components';

import {
    CORRECTED_TOKEN,
    DIRECT_MATCH_TEXT_KEY,
    FILTER_LABEL_TOKEN,
    NO_RESULTS_TEXT_KEY,
    RESULT_COUNT_TOKEN,
    SEARCH_TOKEN,
    SINGLE_FILTER_TEXT_KEY,
} from './constants';
import {
    agnosticReplaceTokens,
    isSearchRuleEntry,
    nonDefaultRefinementsFilter,
} from './utils';

import { StyledHeading, StyledHeadingH2 } from './styles';

export function SearchResultsHeader({
    keywordEntry,
    facetConfig,
    config,
    enableLivePreview,
    livePreviewKeyword,
    resultsPerPage,
}: {
    keywordEntry?: SearchRuleEntry | CategoryLandingPage;
    facetConfig: ContentStackEntryDataProps;
    config: ContentStackEntryDataProps;
    enableLivePreview?: boolean;
    livePreviewKeyword?: string;
    resultsPerPage: number;
}) {
    const { queryParams: searchParamsMap } = useQueryParams();
    const effectiveSearchParamsMap =
        enableLivePreview && livePreviewKeyword
            ? new URLSearchParams({
                  keyword: livePreviewKeyword,
              })
            : searchParamsMap;

    const searchParams = Object.fromEntries(effectiveSearchParamsMap.entries());
    const { searchResult, isSearchResultsLoading } = useSearchContext();

    if (isSearchResultsLoading) {
        return (
            <StyledHeading>
                {/* @ts-ignore TODO: export this or let text as variant */}
                <Skeleton variant="textHeader" />
            </StyledHeading>
        );
    }

    if (!searchResult) {
        return null;
    }

    const { pagination, metrics } = searchResult;

    if (pagination?.totalDocs === 0) {
        return (
            <StyledHeading>
                {getKeyValue(NO_RESULTS_TEXT_KEY, config)}
            </StyledHeading>
        );
    }

    if (Array.isArray(searchParams.keyword)) {
        searchParams.keyword = searchParams.keyword[0];
    }

    const firstDisplayedItemIndex = getFirstItemIndex(
        searchResult.pagination?.currentPage,
        resultsPerPage
    );

    let lastDisplayedItemIndex = getLastItemIndex(
        searchResult.pagination?.currentPage,
        resultsPerPage,
        searchResult.pagination?.totalDocs
    );

    function replaceTokens(template: string | undefined) {
        if (!template) {
            return '';
        }

        return template
            .replace(SEARCH_TOKEN, searchParams.keyword as string)
            .replace(CORRECTED_TOKEN, metrics.correction!)
            .replace(RESULT_COUNT_TOKEN, pagination?.totalDocs.toLocaleString())
            .replace(
                FIRST_ITEM_INDEX_TOKEN,
                firstDisplayedItemIndex.toLocaleString()
            )
            .replace(
                LAST_ITEM_INDEX_TOKEN,
                lastDisplayedItemIndex.toLocaleString()
            )
            .replace(TOTAL_ITEMS_TOKEN, pagination?.totalDocs.toString());
    }

    // If we have results (checked above), but no keyword, show the text without the keyword (used on CLP)
    if (!searchParams.keyword) {
        return (
            <StyledHeadingH2>
                {replaceTokens(getKeyValue(PRODUCT_COUNT_LABEL_KEY, config))}
            </StyledHeadingH2>
        );
    }

    // There are a bunch of special cases for showing the header text based on filters applied
    let refine = searchParams.refine;
    if (Array.isArray(refine)) {
        refine = refine[0] as string;
    }

    const refinements = parseRefineParamToLabels(
        fullyDecodeURI(refine),
        facetConfig
    );

    // 1. If we found results for the search term and have a search page title override
    if (isSearchRuleEntry(keywordEntry) && keywordEntry?.search_page_title) {
        return <StyledHeading>{keywordEntry.search_page_title}</StyledHeading>;
    }

    // We ignore the default filters & price
    const nonDefaultRefinements = refinements
        .filter(nonDefaultRefinementsFilter)
        .filter(({ filterType }) => filterType !== FILTER_TYPE_PRICE); // Price is not a default refinement, but also should not be in title

    // 2. If only one filter is applied, show the filter label and the search term
    if (nonDefaultRefinements.length === 1) {
        return (
            <StyledHeading>
                {replaceSingleFilterToken(
                    replaceTokens(getKeyValue(SINGLE_FILTER_TEXT_KEY, config))
                )}
            </StyledHeading>
        );
    }

    function replaceSingleFilterToken(template: string | undefined) {
        if (!template) {
            return '';
        }

        return template.replace(
            FILTER_LABEL_TOKEN,
            nonDefaultRefinements[0]!.label
        );
    }

    // 5. If we found results for the search term, display it with the number of results
    const resultsTerm = metrics?.correction
        ? metrics.correction
        : (searchParams.keyword as string);

    const resultsCountHeadingLabel = agnosticReplaceTokens(
        getKeyValue(DIRECT_MATCH_TEXT_KEY, config),
        [
            { [`${SEARCH_TOKEN}`]: resultsTerm },
            {
                [`${RESULT_COUNT_TOKEN}`]:
                    pagination?.totalDocs.toLocaleString(),
            },
            {
                [`${FIRST_ITEM_INDEX_TOKEN}`]:
                    firstDisplayedItemIndex.toLocaleString(),
            },
            {
                [`${LAST_ITEM_INDEX_TOKEN}`]:
                    lastDisplayedItemIndex.toLocaleString(),
            },
        ]
    );

    const ResultsCountStyledHeading = () => {
        return metrics?.correction || metrics?.semantic ? (
            // H1 is used for corrected and semantic results headings, so avoid SEO ding from two H1s.
            <StyledHeadingH2>{resultsCountHeadingLabel}</StyledHeadingH2>
        ) : (
            <StyledHeading>{resultsCountHeadingLabel}</StyledHeading>
        );
    };

    return <ResultsCountStyledHeading />;
}
