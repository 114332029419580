'use client';

import { FILTER_TYPE_PRICE } from '../Analytics/constants';

import {
    fullyDecodeURI,
    parseRefineParamToLabels,
} from '@/components/SearchResultsFacets/utils';
import { PageTypes } from '@/constants/index';
import { isCategoryLandingPageEntry } from '@/src/containers/main/utils';
import {
    CategoryLandingPage,
    ContentStackEntryDataProps,
    SearchRuleEntry,
} from '@/types/contentStack';
import { PageType } from '@/types/pages/search';
import { Text } from '@costcolabs/forge-components';
import { useQueryParams } from '@costcolabs/forge-digital-components';

import { nonDefaultRefinementsFilter } from './utils';

export function CategoryPageHeading({
    facetConfig,
    pageType,
    specificPageEntry,
}: {
    facetConfig: ContentStackEntryDataProps;
    pageType: PageType;
    specificPageEntry?: SearchRuleEntry | CategoryLandingPage;
}) {
    const { queryParams: searchParamsMap } = useQueryParams();

    const searchParams = Object.fromEntries(searchParamsMap.entries());

    if (
        pageType === PageTypes.SEARCH ||
        !isCategoryLandingPageEntry(specificPageEntry)
    ) {
        return null;
    }

    // Don't render empty H1 tag is missing
    if (!specificPageEntry.category_id?.category_title) {
        return null;
    }

    // There are a bunch of special cases for showing the header text based on filters applied
    let refine = searchParams.refine;
    if (Array.isArray(refine)) {
        refine = refine[0] as string;
    }

    const refinements = parseRefineParamToLabels(
        fullyDecodeURI(refine),
        facetConfig
    );

    const nonDefaultRefinements = refinements
        .filter(nonDefaultRefinementsFilter)
        .filter(
            ({ label, refinement, filterType }) =>
                filterType !== FILTER_TYPE_PRICE &&
                (label || refinement || filterType)
        ); // Price is not a default refinement, but also should not be in title, and filter out fully empty tags

    if (nonDefaultRefinements.length === 1) {
        return (
            <Text variant={'t1'} component={'h1'} sx={{ marginBottom: '8px' }}>
                {nonDefaultRefinements[0]!.label}{' '}
                {specificPageEntry.category_id.category_title}
            </Text>
        );
    }

    return (
        <Text variant={'t1'} component={'h1'} sx={{ marginBottom: '8px' }}>
            {specificPageEntry.category_id.category_title}
        </Text>
    );
}
