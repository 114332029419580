/**
 * Determine whether an event is a keyboard event.
 * @param e Event
 * @returns boolean
 */
export const getIsKeyboardEvent = (e: React.KeyboardEvent<any>) => {
    return (
        e.nativeEvent instanceof KeyboardEvent ||
        e.type === 'keydown' ||
        ((e.nativeEvent as PointerEvent).pointerType === '' &&
            ((e.nativeEvent as KeyboardEvent).key === 'Enter' ||
                (e.nativeEvent as KeyboardEvent).key === ' '))
    );
};

/**
 * Determine whether a React.KeyboardEvent is a keyboard select event.
 * Filters for Enter and Space bar keydown events.
 * @param e React.KeyboardEvent<any>
 * @returns boolean
 */
export const getIsKeyboardSelect = (e: React.KeyboardEvent<any>) => {
    return e.type === 'keydown' && (e.key === 'Enter' || e.key === ' ');
};
