import { getCategoryAttributes } from '@/src/components/SearchResultsFacets/grs.lib';
import {
    FacetBucketNormalized,
    FacetNormalized,
} from '@/src/components/SearchResultsFacets/types';

type CategoryProps = FacetBucketNormalized & {
    label: string | undefined;
    url: string | undefined;
    id: string | undefined;
    level: number | undefined;
    parent: string | undefined | null;
    l1cateogry: string | undefined | null;
    isSelectedCateogry: boolean;
};

const sortCategories = (a: CategoryProps, b: CategoryProps) => {
    if (a.level && b.level) {
        return a.level - b.level;
    }

    if (a.label && b.label) {
        return a.label.localeCompare(b.label);
    }

    return 0;
};

export const checkCurrentCategory = (
    bucket: FacetBucketNormalized,
    categoryId: string | undefined
) => {
    if (!categoryId) {
        return false;
    }
    return categoryId && bucket.val.toString().startsWith(categoryId);
};

export const getFilteredCategories = (
    facet: FacetNormalized,
    categoryId?: string
) => {
    let filteredCategories: CategoryProps[] = [];
    const selectedCategory = facet.buckets.find(bucket =>
        checkCurrentCategory(bucket, categoryId)
    );
    const selectedCategoryAttributes =
        selectedCategory &&
        getCategoryAttributes(selectedCategory?.val.toString());

    facet.buckets.forEach(bucket => {
        if (!bucket.val) {
            return;
        }

        const categoryAttributes = getCategoryAttributes(
            bucket?.val.toString()
        );

        const category: CategoryProps = {
            ...bucket,
            ...categoryAttributes,
            isSelectedCateogry:
                categoryAttributes.id === selectedCategoryAttributes?.id,
        };

        //  Push all L1 categories if no category is selected
        if (
            (!selectedCategoryAttributes || !categoryId) &&
            category.level &&
            category.level === 1
        ) {
            filteredCategories.push(category);
        }

        // Push the selected L1 category and it's associated L2 categories
        if (
            selectedCategoryAttributes?.level === 1 &&
            (category.id === selectedCategoryAttributes?.id ||
                category.parent === selectedCategoryAttributes?.id)
        ) {
            filteredCategories.push(category);
        }

        // Push the selected L2 and it's assoicated L1 Category and L3 Categories
        if (
            selectedCategoryAttributes?.level === 2 &&
            (category.id === selectedCategoryAttributes?.id ||
                (category.level === 1 &&
                    category.id === selectedCategoryAttributes?.parent) ||
                (category.level === 3 &&
                    category.parent === selectedCategoryAttributes?.id))
        ) {
            filteredCategories.push(category);
        }

        // Push the selected L3 category and it's parent L1 and L2 categories and L4 categories
        if (
            selectedCategoryAttributes?.level === 3 &&
            (category.id === selectedCategoryAttributes?.id ||
                (category.level === 2 &&
                    category.id === selectedCategoryAttributes?.parent) ||
                (category.level === 1 &&
                    category.id === selectedCategoryAttributes.l1cateogry) ||
                (category.level === 4 &&
                    category.parent === selectedCategoryAttributes?.id))
        ) {
            filteredCategories.push(category);
        }

        // Push the selected L4 category and it's parent L1, L2, and L3 categories
        if (
            selectedCategoryAttributes?.level === 4 &&
            (category.id === selectedCategoryAttributes?.id ||
                (category.level === 3 &&
                    category.id === selectedCategoryAttributes?.parent) ||
                (category.level === 2 &&
                    category.id === selectedCategoryAttributes.l2cateogry) ||
                (category.level === 1 &&
                    category.id === selectedCategoryAttributes.l1cateogry))
        ) {
            filteredCategories.push(category);
        }
    });

    const sortedCategories = filteredCategories.sort(sortCategories);

    return sortedCategories;
};
