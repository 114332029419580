import { Button } from '@costcolabs/forge-components';
import { ColorWhite, FontWeightBold } from '@costcolabs/forge-design-tokens';
import { styled } from '@mui/material';

export const FilterPopupContainer = styled(Button)`
    margin-left: 16px;
`;

export const FilterFacetCount = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: '#C13533',
    borderRadius: '50%',
    border: `2px solid ${ColorWhite}`,
    boxShadow: '0px 0px 2px 0px #33333380',
    fontSize: '12px',
    fontWeight: FontWeightBold,
    color: ColorWhite,
    position: 'absolute',
    right: '-8px',
    top: '-10px',
    width: '20px',
    height: '20px',
});
