'use client';

import { getKeyValue } from '@/services/content/business.lib';
import { useSearchContext } from '@/services/search/SearchProvider';
import type { ContentStackEntryDataProps } from '@/types/contentStack';

import { NO_MATCH_TEXT_KEY, TRY_ANOTHER_TEXT_KEY } from './constants';

import {
    StyledHeadingTwoPartH1,
    StyledHeadingTwoPartH2,
    StyledHeadingTwoPartParent,
} from './styles';

export const SemanticResultsHeader = ({
    config,
}: {
    config: ContentStackEntryDataProps;
}) => {
    const { searchResult } = useSearchContext();
    if (!searchResult) {
        return null;
    }
    const { metrics } = searchResult;
    // If no semantic, return null
    if (!metrics?.semantic) {
        return null;
    }

    return (
        <StyledHeadingTwoPartParent>
            {/* "We were not able to find a match..." */}
            <StyledHeadingTwoPartH1>
                {getKeyValue(NO_MATCH_TEXT_KEY, config)}
            </StyledHeadingTwoPartH1>
            {/* "Try another..." */}
            <StyledHeadingTwoPartH2>
                {getKeyValue(TRY_ANOTHER_TEXT_KEY, config)}
            </StyledHeadingTwoPartH2>
        </StyledHeadingTwoPartParent>
    );
};
