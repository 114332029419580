'use client';

import SearchPageDigitalDataLayer from '../Analytics/SearchPageDigitalDataLayer';
import { AnalyticsObserver } from '../AnalyticsObserver';
import { useCriteoAnalytics } from '../AnalyticsObserver/utils';
import { isNonDefaultFacetApplied } from '../SearchResultsFacets/utils';
import { Variants } from 'node_modules/@costcolabs/forge-digital-components/dist/components/Tiles/ProductTile/constants';

import React, { Children, ReactNode, useMemo } from 'react';

import { SEARCH_RESULT_CONTAINER_ID } from '@/constants/index';
import { getKeyValue } from '@/services/content/business.lib';
import { isSearchResultGRS } from '@/services/search/services/Grs';
import { isSearchResultLW } from '@/services/search/services/LucidWorks';
import { isBDSite } from '@/services/search/utils';
import {
    COMPONENT_TYPE_UI,
    DISPLAY_RANK,
    KEY_SEARCH_INDEX,
    SEARCH,
    TRACK_TYPE_MULTI_ATTR,
    TRACK_TYPE_NAVIGATION,
    TRAVEL_DESTINATION,
    TRAVEL_ITEM_PID,
} from '@/src/components/Analytics/constants';
import {
    A11Y_CRITEO_PRODUCT_TILE_SEPARATOR,
    A11Y_CRITEO_TITLE,
    product_display_style,
} from '@/src/containers/main/constants';
import { useSearchContext } from '@/src/services/search/SearchProvider';
import {
    ComposerData,
    ContentStackEntryDataProps,
    SearchConfig,
    SearchPageConfigProperties,
} from '@/src/types/contentStack';
import { Grid } from '@costcolabs/forge-components';
import { ColorGray500 } from '@costcolabs/forge-design-tokens';
import {
    Analytics,
    CriteoButterflyDisplay,
    CriteoProductTile,
    LocaleProps,
    ProductTileSkeleton,
    ProductTileUI,
    findCriteoPlacement,
    useCheckScreen,
    useCriteo,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { Box } from '@mui/system';

import {
    ExtendedSearchResult,
    isExtendedSearchResultGRS,
    isExtendedSearchResultLW,
} from './types';

// type BeaconType = 'OnClickBeacon' | 'OnLoadBeacon' | 'OnViewBeacon';

// type CriteoProduct = Record<BeaconType, string> & {
//     ProductId: string;
//     ParentSKU: string;
// };

// type ProductArray = CriteoProduct[];

// type CriteoRendering = Record<string, string>;

// type Placement = {
//     format: string;
//     OnClickBeacon: string;
//     OnLoadBeacon: string;
//     OnViewBeacon: string;
//     rendering: CriteoRendering | string; // TODO: Type the record correctly based on Commerce data
//     products: ProductArray;
// };

export function SearchResultsGrid({
    config,
    lang,
    productTileConfigData,
    composerData,
    searchConfigData,
    fullRowAdEntries,
    isCategoryPage,
    pageType,
    doFocusFirstItem,
}: {
    config: ContentStackEntryDataProps<SearchPageConfigProperties>;
    lang: LocaleProps;
    productTileConfigData: unknown;
    composerData?: ComposerData;
    searchConfigData?: SearchConfig;
    fullRowAdEntries: ReactNode;
    isCategoryPage?: boolean;
    pageType?: string;
    doFocusFirstItem?: number;
}) {
    const { queryParams: searchParams } = useQueryParams();

    const isMarketingContentHidden = isNonDefaultFacetApplied(searchParams);

    const { searchResult, isSearchResultsLoading, searchService } =
        useSearchContext();
    const { searchPageData } = useCriteo();
    const { isMobile, isTablet } = useCheckScreen();

    const baseKey = isCategoryPage
        ? 'viewCategory'
        : isBDSite(process.env.NEXT_PUBLIC_SITE)
          ? 'viewSearchResult'
          : 'viewSearchResults';
    const inGridPlacementKey = `${baseKey}_API_${isMobile ? 'mobile' : 'desktop'}-ingrid`;

    const inGridPlacement = useMemo(() => {
        return findCriteoPlacement(
            inGridPlacementKey,
            searchPageData?.placements
        );
    }, [searchPageData, inGridPlacementKey]);

    const butterflyPlacementKey = `${baseKey}_API_${isMobile ? 'mobile' : 'desktop'}-Butterfly`;

    const butterflyPlacements = useMemo(() => {
        let placements = [];

        let firstPlacement = findCriteoPlacement(
            `${butterflyPlacementKey}1`,
            searchPageData?.placements
        );

        if (firstPlacement) {
            placements.push(firstPlacement);
        }

        let secondPlacement = findCriteoPlacement(
            `${butterflyPlacementKey}2`,
            searchPageData?.placements
        );

        if (secondPlacement) {
            placements.push(secondPlacement);
        }

        return placements;
    }, [searchPageData, butterflyPlacementKey]);

    const analytics = useCriteoAnalytics({
        placement: inGridPlacement,
    });

    let maxColumns = 4;
    if (isTablet) {
        maxColumns = 3;
    } else if (isMobile) {
        maxColumns = 2;
    }
    const singleItemGridSize = 12 / maxColumns; // This determines the size of the grid items

    if (isSearchResultsLoading) {
        return (
            <Grid
                container
                style={{ margin: 0 }}
                spacing={6}
                id={SEARCH_RESULT_CONTAINER_ID}
            >
                {[...Array(12).keys()].map((_, index) => {
                    return (
                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            key={`criteo_${index}_skeleton`}
                            size={{ xs: singleItemGridSize }}
                        >
                            <ProductTileSkeleton />
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    if (!searchResult) {
        return null;
    }

    let searchResultsData =
        searchService === 'grs' && isSearchResultGRS(searchResult)
            ? searchResult.searchResult.results
            : searchService === 'lucidworks' && isSearchResultLW(searchResult)
              ? searchResult.docs
              : [];

    let resultsToRender: ExtendedSearchResult[] = searchResultsData;

    const rowToRenderFullRowAdOn = searchConfigData?.ingrid_placement_row_nth_;

    const hasInGridFullRowAds = !!composerData?.inGridPlacements?.length;

    const columnToRenderSponsoredProductsOn = maxColumns;

    const a11yCriteo = {
        a11yTitle: getKeyValue(A11Y_CRITEO_TITLE, config),
        a11yProductTileSeperator: getKeyValue(
            A11Y_CRITEO_PRODUCT_TILE_SEPARATOR,
            config
        ),
    };

    // Get the first butterfly position found
    const butterflyPositionConfig =
        searchConfigData?.external_ingrid_position?.find(
            ({ placement_type }) => {
                return placement_type === 'criteo_butterFly';
            }
        );

    const butterflyPlacementRowNth =
        butterflyPositionConfig?.placement_row_nth_;
    let rowToRenderButterflyOn = butterflyPlacementRowNth?.[0];

    if (
        !isMarketingContentHidden &&
        ((searchConfigData?.enable_criteo_sponsored_products &&
            inGridPlacement?.products?.length) ||
            (typeof rowToRenderButterflyOn === 'number' &&
                butterflyPlacements?.length))
    ) {
        // Marketing placement
        let currentCriteoProductTileIndex = 0;
        let currentCriteoButterflyIndex = 0;

        resultsToRender = searchResultsData.reduce(
            (newDocs: ExtendedSearchResult[], doc) => {
                const placeSponsoredAd = () => {
                    let newDocsLength = newDocs.length;
                    if (
                        (inGridPlacement?.products?.[
                            currentCriteoProductTileIndex
                        ] && newDocsLength + 1) %
                            columnToRenderSponsoredProductsOn ===
                        0
                    ) {
                        newDocs.push({
                            isCriteoProductTileAd: true,
                            criteoIndex: currentCriteoProductTileIndex,
                        });
                        currentCriteoProductTileIndex += 1;
                    }
                };
                const placeButterflyAd = () => {
                    let newDocsLength = newDocs.length;
                    let row = newDocsLength / maxColumns;

                    if (
                        typeof rowToRenderButterflyOn === 'number' &&
                        butterflyPlacements?.[currentCriteoButterflyIndex] &&
                        (row + 1) % rowToRenderButterflyOn === 0
                    ) {
                        newDocs.push({
                            isCriteoButterflyAd: true,
                            criteoIndex: currentCriteoButterflyIndex,
                        });
                        newDocs.push({
                            isCriteoButterflyFiller: true,
                        });
                        currentCriteoButterflyIndex += 1;
                        if (!butterflyPositionConfig?.iterative) {
                            rowToRenderButterflyOn =
                                butterflyPlacementRowNth?.[
                                    currentCriteoButterflyIndex
                                ];
                        }
                    }
                };

                // Try to place sponsored ad
                placeSponsoredAd();
                // Try to place buttery ad
                placeButterflyAd();
                // Try to place sponsored ad if new length calls for it (this handles the case of three)
                placeSponsoredAd();

                newDocs.push(doc);

                return newDocs;
            },
            []
        );

        if (
            resultsToRender.length % maxColumns === maxColumns - 1 &&
            inGridPlacement?.products?.[currentCriteoProductTileIndex + 1]
        ) {
            resultsToRender.push({
                isCriteoProductTileAd: true,
                criteoIndex: currentCriteoProductTileIndex + 1,
            });
        }
    }

    const fullRowAdEntriesArray = Children.toArray(fullRowAdEntries);

    // Not marketing placmenent
    return (
        <Grid
            container
            style={{ margin: 0 }}
            spacing={isMobile ? 4 : 6}
            id={SEARCH_RESULT_CONTAINER_ID}
            sx={{
                '& > div': {
                    borderBottom: `1px solid ${ColorGray500}`,
                    marginBottom: '-1px',
                    paddingTop: '24px',
                    paddingBottom: '24px',
                },
                '& > div:empty': {
                    display: 'none',
                },
                borderBottom: `1px solid ${ColorGray500}`,
            }}
        >
            {resultsToRender.map((doc, index) => {
                let ProductTileComponent;
                let criteoInGridOnLoadBeaconFired = false;

                if (!isMarketingContentHidden && doc.isCriteoProductTileAd) {
                    if (inGridPlacement?.products?.length) {
                        let product =
                            typeof doc.criteoIndex === 'number' &&
                            inGridPlacement.products[doc.criteoIndex];

                        if (product) {
                            ProductTileComponent = (
                                <Analytics
                                    analyticData={{
                                        adItem: {
                                            title: 'sponsored products',
                                            type: 'product',
                                            style: 'block',
                                        },
                                        itemCuration: 'criteo',
                                        position: 'in grid',
                                        title: 'sponsored products - search in grid',
                                        trackType: 'ad',
                                    }}
                                >
                                    <CriteoProductTile
                                        key={`${product.ParentSKU}_criteo_${doc.criteoIndex}_tile`}
                                        variant={
                                            product_display_style.product_card_display_style as Variants
                                        }
                                        locale={lang}
                                        showActionButton={
                                            product_display_style.show_action_button
                                        }
                                        showReviews={
                                            product_display_style.show_reviews
                                        }
                                        showCompareProduct={!isMobile}
                                        placement={inGridPlacement}
                                        products={[product]}
                                        inventoryAware={
                                            product_display_style.inventory_aware_listings
                                        }
                                        itemNumber={product.ParentSKU}
                                        // @ts-expect-error missing type
                                        configData={productTileConfigData}
                                        isSponsored={true}
                                        sx={{
                                            height: '100%',
                                        }}
                                        WrapperComponent={(
                                            props: React.PropsWithChildren
                                        ) => {
                                            if (doc.criteoIndex !== 0) {
                                                return (
                                                    <Grid
                                                        size={{
                                                            xs: singleItemGridSize,
                                                        }}
                                                        key={`${product.ParentSKU}_criteo_${doc.criteoIndex}_wrapper`}
                                                    >
                                                        {props.children}
                                                    </Grid>
                                                );
                                            } else {
                                                return (
                                                    <Grid
                                                        key={`${product.ParentSKU}_criteo_${doc.criteoIndex}_wrapper`}
                                                        size={{
                                                            xs: singleItemGridSize,
                                                        }}
                                                    >
                                                        <AnalyticsObserver
                                                            observer={{
                                                                minimumViewTime:
                                                                    analytics.minimumViewTime,
                                                                onLoad: () => {
                                                                    if (
                                                                        !criteoInGridOnLoadBeaconFired
                                                                    ) {
                                                                        analytics.onPlacementLoad();
                                                                        criteoInGridOnLoadBeaconFired =
                                                                            true;
                                                                    }
                                                                },
                                                                onView: () =>
                                                                    analytics.onPlacementViewed(),
                                                                viewThreshold:
                                                                    analytics.viewThreshold,
                                                            }}
                                                            sx={{
                                                                height: '100%',
                                                            }}
                                                        >
                                                            {props.children}
                                                        </AnalyticsObserver>
                                                    </Grid>
                                                );
                                            }
                                        }}
                                    />
                                </Analytics>
                            );
                        }
                    } else {
                        // Skeleton: this will render on the server until hydrated on the client to get into the above if statement
                        ProductTileComponent = (
                            <Grid
                                size={{
                                    xs: 12,
                                }}
                                key={`criteo_${doc.criteoIndex}_skeleton`}
                            >
                                <ProductTileSkeleton />
                            </Grid>
                        );
                    }
                } else if (
                    !isMarketingContentHidden &&
                    (doc.isCriteoButterflyAd || doc.isCriteoButterflyFiller)
                ) {
                    // Criteo Butterfly
                    if (doc.isCriteoButterflyFiller) {
                        return <></>; // This is to keep the index correct for future tiles, but shouldn't be rendered
                    }
                    const butterflyPlacement =
                        doc.criteoIndex !== undefined
                            ? butterflyPlacements[doc.criteoIndex]
                            : undefined;
                    const products = butterflyPlacement?.products;
                    const ParentSKUs =
                        products?.map(
                            ({ ParentSKU }: { ParentSKU: string }) => ParentSKU
                        ) || [];

                    return (
                        <Grid
                            size={{
                                xs: singleItemGridSize * 2,
                            }}
                            key={`butterfly_${doc.criteoIndex}_${index}`}
                            display="flex"
                        >
                            <Box
                                sx={{
                                    minWidth: '100%',
                                    minHeight: '100%',
                                }}
                            >
                                <CriteoButterflyDisplay
                                    a11yCriteo={a11yCriteo}
                                    contentStackEntry={{
                                        criteo_placement_name: `butterfly_${doc.criteoIndex}`,
                                        product_display_style,
                                    }}
                                    locale={lang}
                                    products={butterflyPlacement?.products}
                                    placement={butterflyPlacement}
                                    ParentSKUs={ParentSKUs}
                                    productTileSx={{
                                        minHeight: isMobile ? '100%' : '97%',
                                        paddingRight: `2px`,
                                    }}
                                />
                            </Box>
                        </Grid>
                    );
                } else {
                    const lwData =
                        searchService === 'lucidworks' &&
                        isExtendedSearchResultLW(doc)
                            ? doc
                            : undefined;

                    const grsData =
                        searchService === 'grs' &&
                        isExtendedSearchResultGRS(doc)
                            ? doc
                            : undefined;

                    const isTravelItem =
                        !!lwData?.content_type?.includes(TRAVEL_DESTINATION) ||
                        !!grsData?.product.attributes[
                            'product_type'
                        ]?.text?.[0]?.includes(TRAVEL_DESTINATION);

                    ProductTileComponent = (
                        <Grid
                            size={{
                                xs: singleItemGridSize,
                            }}
                            key={`${lwData?.item_number}_${index}`}
                            display="flex"
                        >
                            {lwData && !lwData?.item_chdi_eligible && (
                                <SearchPageDigitalDataLayer
                                    productData={{
                                        pid: isTravelItem
                                            ? TRAVEL_ITEM_PID
                                            : lwData.group_id,
                                        sku: isTravelItem
                                            ? TRAVEL_ITEM_PID
                                            : lwData.item_number,
                                        searchDisplayRank: index + 1,
                                    }}
                                />
                            )}
                            {grsData &&
                                !grsData.product.attributes.chdi_eligible
                                    ?.numbers?.[0] &&
                                !grsData.variantRollupValues[
                                    'attributes.chdi_eligible'
                                ]?.includes(1) && (
                                    <SearchPageDigitalDataLayer
                                        productData={{
                                            pid: isTravelItem
                                                ? TRAVEL_ITEM_PID
                                                : grsData.product.id,
                                            sku: isTravelItem
                                                ? TRAVEL_ITEM_PID
                                                : grsData.id,
                                            searchDisplayRank: index + 1,
                                        }}
                                    />
                                )}
                            <Analytics
                                analyticData={{
                                    component: isTravelItem
                                        ? {
                                              identifier: SEARCH,
                                              type: COMPONENT_TYPE_UI,
                                          }
                                        : undefined,
                                    trackedData: !isTravelItem
                                        ? [
                                              {
                                                  key:
                                                      pageType === 'search'
                                                          ? KEY_SEARCH_INDEX
                                                          : DISPLAY_RANK,
                                                  value: (index + 1).toString(),
                                              },
                                          ]
                                        : undefined,
                                    trackType: isTravelItem
                                        ? TRACK_TYPE_NAVIGATION
                                        : TRACK_TYPE_MULTI_ATTR,
                                }}
                            >
                                <ProductTileUI
                                    variant={
                                        product_display_style.product_card_display_style as Variants
                                    }
                                    locale={lang}
                                    showActionButton={
                                        product_display_style.show_action_button
                                    }
                                    showReviews={
                                        product_display_style.show_reviews
                                    }
                                    lwData={lwData}
                                    grsData={grsData}
                                    inventoryAware={
                                        product_display_style.inventory_aware_listings
                                    }
                                    showCompareProduct={!isMobile}
                                    // @ts-expect-error missing type
                                    configData={productTileConfigData}
                                    pageType={pageType}
                                    doFocus={
                                        doFocusFirstItem && index === 0
                                            ? doFocusFirstItem
                                            : false
                                    }
                                />
                            </Analytics>
                        </Grid>
                    );
                }

                let FullRowAdComponent;
                let row = (index + 1) / maxColumns;

                if (
                    hasInGridFullRowAds &&
                    typeof rowToRenderFullRowAdOn === 'number' &&
                    row % rowToRenderFullRowAdOn === 0
                ) {
                    let adIndex = row / rowToRenderFullRowAdOn - 1;

                    let fullRowAdData =
                        composerData?.inGridPlacements?.[adIndex];

                    if (fullRowAdData) {
                        FullRowAdComponent = fullRowAdEntriesArray[adIndex];
                    }
                }

                const lwData =
                    searchService === 'lucidworks' &&
                    isExtendedSearchResultLW(doc)
                        ? doc
                        : undefined;

                const grsData =
                    searchService === 'grs' && isExtendedSearchResultGRS(doc)
                        ? doc
                        : undefined;

                return (
                    <React.Fragment key={index}>
                        {ProductTileComponent}
                        {!isMarketingContentHidden && FullRowAdComponent && (
                            <Grid
                                size={{
                                    xs: 12,
                                }}
                                key={`${lwData?.item_number ?? grsData?.id}_${index}_fullRow`}
                            >
                                {FullRowAdComponent}
                            </Grid>
                        )}
                    </React.Fragment>
                );
            })}
        </Grid>
    );
}
