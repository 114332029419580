import { COSTCOGROCERY_FACET_KEY } from '../SearchResultsFacets/constants';

import { getKeyValue } from '@/services/content/business.lib';
import { ContentStackEntryDataProps } from '@/types/contentStack';
import {
    ColorBlue600,
    ColorRed500,
    FontSize300,
    FontWeightBold,
} from '@costcolabs/forge-design-tokens';
import type { LocaleProps } from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';

export function CostcoGroceryLabel({
    locale,
    config,
}: {
    locale: LocaleProps;
    config: ContentStackEntryDataProps;
}) {
    const [labelPartOne, labelPartTwo] = (
        getKeyValue(COSTCOGROCERY_FACET_KEY, config) ?? ''
    ).split(' ');

    if (!labelPartOne || !labelPartTwo) {
        return null;
    }

    return (
        <>
            <Box
                component="span"
                // FR translation begins with french word for grocery,
                // so we swap colors to ensure Costco is red & Grocery is blue
                sx={{
                    color: locale === 'fr-ca' ? ColorBlue600 : ColorRed500,
                    fontWeight: FontWeightBold,
                    fontSize: FontSize300,
                }}
            >
                {labelPartOne}
            </Box>
            <Box
                component="span"
                sx={{
                    color: locale === 'fr-ca' ? ColorRed500 : ColorBlue600,
                    fontWeight: FontWeightBold,
                    fontSize: FontSize300,
                }}
            >
                {labelPartTwo}
            </Box>
        </>
    );
}
