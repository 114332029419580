'use client';

import { SELECTED_FACETS } from '@/src/components/SearchResultsFacets/constants';
import { useSearchContext } from '@/src/services/search/SearchProvider';
import type {
    ContentStackEntryDataProps,
    GRSFacetConfigProperties,
} from '@/types/contentStack';
import { Box } from '@mui/material';

import { GRSFilters } from './GRSFilters';
import { LWFilters } from './LWFilters';

export function SelectedFilters({
    config,
    grsFacetConfig,
}: {
    config: ContentStackEntryDataProps;
    grsFacetConfig:
        | ContentStackEntryDataProps<GRSFacetConfigProperties>
        | undefined;
}) {
    const { searchService } = useSearchContext();

    return (
        <Box
            sx={{
                display: 'flex',
                margin: '16px 0 0',
                '@media (max-width: 768px)': {
                    margin: '12px 0 0 0',
                },
                flexWrap: 'wrap',
            }}
            data-testid={SELECTED_FACETS}
        >
            {searchService === 'lucidworks' && <LWFilters config={config} />}
            {searchService === 'grs' && grsFacetConfig && (
                <GRSFilters config={config} grsFacetConfig={grsFacetConfig} />
            )}
        </Box>
    );
}
