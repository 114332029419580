'use client';

import FilterAwareVisibilityToggle from '../DisplayGroup/FilterAwareVisibilityToggle';

import { useState } from 'react';

import { VideoSpotlightClient } from '@/components/SearchResultsFacets/VideoSpotlightClient';
import { NO_RESULTS_CONTAINER_ID } from '@/src/app/[lang]/(customers)/s/constants';
import { SearchResultsGrid } from '@/src/components/SearchResultsGrid';
import { SearchResultsPagination } from '@/src/components/SearchResultsPagination';
import SearchVisibilityToggle from '@/src/containers/main/SearchVisibilityToggle';
import {
    ComposerData,
    ContentStackEntryDataProps,
    SearchConfig,
    SearchPageConfigProperties,
} from '@/types/contentStack';
import { PageType } from '@/types/pages/search';
import { Divider } from '@costcolabs/forge-components';
import type { LocaleProps } from '@costcolabs/forge-digital-components';
import { Box } from '@mui/system';

export function SearchResultRegion({
    lang,
    composerData,
    config,
    fullRowAdEntries,
    productTileConfigData,
    searchConfigData,
    displayRegion = true,
    isCategoryPage,
    pageType,
}: {
    lang: LocaleProps;
    composerData?: ComposerData;
    config: ContentStackEntryDataProps<SearchPageConfigProperties>;
    fullRowAdEntries?: JSX.Element[];
    productTileConfigData: any;
    searchConfigData?: SearchConfig;
    displayRegion?: boolean;
    isCategoryPage?: boolean;
    pageType?: PageType;
}) {
    // Counter and handler to increment when a pagination event happens.
    const [doFocusFirstItem, setDoFocusFirstItem] = useState(0);
    const handleFocus = () => {
        setDoFocusFirstItem(prev => {
            return prev + 1;
        });
    };

    return (
        <SearchVisibilityToggle
            searchEnabled={displayRegion}
            fallback={<Box id={NO_RESULTS_CONTAINER_ID} />}
        >
            <FilterAwareVisibilityToggle>
                <VideoSpotlightClient
                    lang={lang}
                    position={'grid'}
                    pageType={pageType}
                />
            </FilterAwareVisibilityToggle>
            <SearchResultsGrid
                config={config}
                lang={lang}
                productTileConfigData={productTileConfigData}
                composerData={composerData}
                searchConfigData={searchConfigData}
                fullRowAdEntries={fullRowAdEntries}
                isCategoryPage={isCategoryPage}
                pageType={pageType}
                doFocusFirstItem={doFocusFirstItem}
            />

            <SearchResultsPagination
                config={config}
                handleFocus={handleFocus}
            />
            <Divider sx={{ margin: '10px 0 20px' }} />
        </SearchVisibilityToggle>
    );
}
