'use client';

import { Box, styled } from '@mui/material';

export const SearchContainer = styled(Box)<{ layout: 'row' | 'column' }>(
    props => ({
        display: 'flex',
        flexDirection: props.layout,
        '& .MuiPagination-ul': {
            justifyContent: 'center',
        },
        '& p': {
            margin: 0,
        },
    })
);

export const StyledMainColumn = styled(Box)({
    minWidth: 'calc(75% - 20px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '@media (max-width: 767px)': {
        minWidth: '100%',
    },
});
