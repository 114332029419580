'use client';

import Script from 'next/script';

import { useEffect, useState } from 'react';

import { ADOBE_SESSION_ID_KEY } from '@/src/components/Adobe/constants';
import { useSearchContext } from '@/src/services/search/SearchProvider';
import {
    createUUID,
    useBrowseContext,
    useLocalForage,
} from '@costcolabs/forge-digital-components';

import { IdentityProps } from './types';

const AdobeClient = ({ scriptSrc }: { scriptSrc: string }) => {
    const [isWindowAvailable, setIsWindowAvailable] = useState(false);
    const { thirdPartyInfo } = useBrowseContext();
    const { searchResult } = useSearchContext();
    const { setValue: setAdobeSessionId } = useLocalForage<string>(
        ADOBE_SESSION_ID_KEY,
        createUUID()
    );

    const { oneTrust } = thirdPartyInfo;
    const { isTargetingEnabled } = oneTrust;

    const onLoad = () => {
        window.alloy('getIdentity').then(function (result: IdentityProps) {
            const adobeSessionId = result?.identity?.ECID;
            setAdobeSessionId(adobeSessionId);
        });
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // The Adobe script we load contains logic that will load additional scripts. Setting
            // digitalData.sitePlatform to "react" allows the script to know which additional scripts to use.
            window.digitalData = {
                ...window.digitalData,
                sitePlatform: 'react',
            };
            setIsWindowAvailable(true);
        }
    }, []);

    if (
        !isWindowAvailable ||
        !isTargetingEnabled ||
        !searchResult?.isAdTargetingEnabled
    ) {
        return null;
    }

    return (
        <Script
            id="AdobeScript"
            onLoad={onLoad}
            src={scriptSrc}
            strategy="afterInteractive"
        />
    );
};

export { AdobeClient };
