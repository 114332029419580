'use client';

import { A11Y_FILTER_DESCRIBED_BY } from '../../SearchResultsFacets/constants';

import {
    StyledList,
    StyledListItem,
} from '@/components/SearchResultsFacets/styles';
import { checkRefinementApplied } from '@/components/SearchResultsFacets/utils';
import { OUT_OF_STOCK_TEXT_KEY } from '@/constants/index';
import { getKeyValue } from '@/services/content/business.lib';
import { SearchServiceProps } from '@/services/search/types';
import {
    FILTER,
    FILTER_REMOVE,
    FILTER_TYPE_LOCATION,
    FILTER_VAL,
    TRACK_TYPE_MULTI_ATTR,
} from '@/src/components/Analytics/constants';
import { FacetNormalized } from '@/src/components/SearchResultsFacets/types';
import useRefinement from '@/src/hooks/useRefinement';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { Checkbox } from '@costcolabs/forge-components';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export function LocationFilterView({
    facet,
    resultsText,
    config,
    searchService,
}: {
    facet: FacetNormalized;
    resultsText: string;
    config: ContentStackEntryDataProps;
    searchService: SearchServiceProps;
}) {
    const { handleDoRefine } = useRefinement({ searchService });
    const { queryParams: searchParams } = useQueryParams();

    return (
        // @ts-ignore TODO wtf?
        <StyledList sx={{ marginTop: '15px' }}>
            {facet.buckets.map(bucket => {
                const isChecked = checkRefinementApplied({
                    facet,
                    bucket,
                    searchParams,
                    searchService,
                });
                const label =
                    searchService === 'grs'
                        ? facet.label
                        : getKeyValue(OUT_OF_STOCK_TEXT_KEY, config);

                return (
                    <StyledListItem
                        key={bucket.val.toString()}
                        data-private={bucket.leads_to_fsa_chdi}
                        data-bi-private={bucket.leads_to_fsa_chdi}
                    >
                        <Box>
                            <Analytics
                                analyticData={{
                                    trackedData: [
                                        {
                                            key: isChecked
                                                ? FILTER_REMOVE
                                                : FILTER,
                                            value: FILTER_TYPE_LOCATION,
                                        },
                                        {
                                            key: FILTER_VAL,
                                            value: label,
                                        },
                                    ],
                                    trackType: TRACK_TYPE_MULTI_ATTR,
                                    doNotTrack: bucket.leads_to_fsa_chdi,
                                }}
                            >
                                <Checkbox
                                    ariaDescribeText={A11Y_FILTER_DESCRIBED_BY}
                                    onChange={event =>
                                        handleDoRefine(
                                            facet,
                                            bucket,
                                            event.target.checked
                                        )
                                    }
                                    label={
                                        (
                                            <>
                                                {label}{' '}
                                                <Box sx={visuallyHidden}>
                                                    {`${bucket.count.toLocaleString()} ${resultsText}`}
                                                </Box>
                                            </>
                                        ) as unknown as string
                                    }
                                    isChecked={!!isChecked}
                                    id={`${facet.facetKey}-${bucket.val.toString().split(' ').join('+')}`}
                                />
                            </Analytics>
                        </Box>
                    </StyledListItem>
                );
            })}
        </StyledList>
    );
}
