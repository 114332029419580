'use client';

import { StyledButton } from '../styles';

import { useEffect, useState } from 'react';

import {
    getSelectLWFacetsFromUrl,
    updateLWRefinements,
} from '@/components/SearchResultsFacets/utils';
import { getKeyValue } from '@/services/content/business.lib';
import { isSearchResultLW } from '@/services/search/services/LucidWorks';
import {
    FILTER_REMOVE,
    FILTER_VAL,
    TRACK_TYPE_MULTI_ATTR,
} from '@/src/components/Analytics/constants';
import { FACET_OPTION_SELECTED_FILTER } from '@/src/components/SearchResultsFacets/constants';
import { useSearchContext } from '@/src/services/search/SearchProvider';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import {
    ColorBlue500,
    SpaceXs,
    SpaceXxs,
} from '@costcolabs/forge-design-tokens';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { XThin } from '@costcolabs/forge-icons';

export function LWFilters({ config }: { config: ContentStackEntryDataProps }) {
    const { searchResult } = useSearchContext();
    const [selectedFacets, setSelectedFacets] = useState<
        Array<{ label: string; filterType: string; refinement?: string }>
    >([]);

    const { queryParams: searchParams, setQueryParams } = useQueryParams();

    const handleDelete = (refinement: string) => {
        updateLWRefinements(setQueryParams, refinement, false, searchParams);
    };

    useEffect(() => {
        if (!searchResult || !isSearchResultLW(searchResult)) {
            return undefined;
        }

        const selectFacets = getSelectLWFacetsFromUrl(
            searchParams,
            config,
            searchResult.facets
        );

        setSelectedFacets(selectFacets ?? []);
    }, [searchParams, config, searchResult]);
    const removeFilterText = getKeyValue('a11y.removefiltertext', config);
    return (
        <>
            {selectedFacets.map(({ label, filterType, refinement }, index) => {
                return (
                    <Analytics
                        analyticData={{
                            trackedData: [
                                {
                                    key: FILTER_REMOVE,
                                    value: filterType!,
                                },
                                {
                                    key: FILTER_VAL,
                                    value: label!,
                                },
                            ],
                            trackType: TRACK_TYPE_MULTI_ATTR,
                        }}
                        key={refinement + '-analytics'}
                    >
                        <StyledButton
                            key={`${refinement}-${index}-analytics`}
                            onClick={() => handleDelete(refinement!)}
                            aria-label={`${label}, ${removeFilterText}`}
                            uniqueId={`${FACET_OPTION_SELECTED_FILTER}_${label}`}
                            sx={{
                                marginBottom: '14px',
                                padding: `${SpaceXxs} ${SpaceXs}`,
                            }}
                        >
                            {label}
                            <XThin
                                style={{
                                    color: ColorBlue500,
                                    height: '14px',
                                    paddingLeft: '12px',
                                }}
                            />
                        </StyledButton>
                    </Analytics>
                );
            })}
        </>
    );
}
