import { GRSSearchResultWithInventory } from '@/services/search/services/GRS.types';
import { SearchResultDocsProps } from '@/types/searchQuery';

export type ExtendedSearchResult =
    | ExtendedSearchResultLW
    | ExtendedSearchResultGRS;

export type ExtendedSearchResultLW = Partial<SearchResultDocsProps> & {
    isCriteoProductTileAd?: boolean;
    criteoIndex?: number;
    isCriteoButterflyAd?: boolean;
    isCriteoButterflyFiller?: boolean;
};

export type ExtendedSearchResultGRS = GRSSearchResultWithInventory & {
    isCriteoProductTileAd?: boolean;
    criteoIndex?: number;
    isCriteoButterflyAd?: boolean;
    isCriteoButterflyFiller?: boolean;
};

export const isExtendedSearchResultLW = (
    result: any
): result is ExtendedSearchResultLW => {
    return result.item_number;
};

export const isExtendedSearchResultGRS = (
    result: any
): result is ExtendedSearchResultGRS => {
    return result?.product;
};
