'use client';

import { Button } from '@costcolabs/forge-components';
import {
    ColorBlue500,
    ColorTextLink,
    FontLineHeightLg,
    FontWeightDefault,
    SpaceMd,
} from '@costcolabs/forge-design-tokens';
import { styled } from '@mui/material';

export const StyledButton = styled(Button)({
    padding: '4px 8px 4px 8px',
    fontSize: '14px',
    border: `1px solid ${ColorBlue500}`,
    color: ColorTextLink,
    margin: `0 ${SpaceMd} 0 0`,
    fontWeight: FontWeightDefault,
    lineHeight: FontLineHeightLg,
    backgroundColor: 'white',
    height: '32px',
    '&:hover': {
        backgroundColor: '#d9d9d9',
    },
    '&:focus': {
        backgroundColor: '#d9d9d9',
    },
});
