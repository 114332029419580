'use client';

import { useEffect, useState } from 'react';

import { SEARCH_RESULT_CONTAINER_ID } from '@/constants/index';
import { updatePageTitle } from '@/services/search/utils';
import SearchPageDigitalDataLayer from '@/src/components/Analytics/SearchPageDigitalDataLayer';
import { useSearchContext } from '@/src/services/search/SearchProvider';
import { LocaleType, pageNameType } from '@/src/types/pages/search';
import { modernizeCategoryLink } from '@/utils/links';
import { Breadcrumbs } from '@costcolabs/forge-components';
import {
    type LocaleProps,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';

import { SkipToResultsLink } from './SkipToResultsLink';

import { BreadCrumbContainerStyle } from './styles';

export function CategoryBreadCrumbClient({
    site,
    lang,
    arialLabel,
    skipLinkText,
    initialValues = [],
    categoryUrlPath,
    displaySearchFilters,
}: {
    categoryUrlPath: string | undefined;
    site: string;
    lang: LocaleProps;
    arialLabel: string;
    skipLinkText: string;
    initialValues?: Array<{ name: string; link: string }>;
    displaySearchFilters: boolean;
}) {
    const { queryParams: searchParams } = useQueryParams();
    const { searchResult, isSearchResultsLoading } = useSearchContext();
    const [breadcrumbs, setBreadcrumbs] =
        useState<Array<{ name: string; link: string }>>(initialValues);

    useEffect(() => {
        if (!searchResult || !searchResult.breadcrumb) {
            return;
        }

        setBreadcrumbs([
            { name: 'Home', link: '/' },
            ...searchResult.breadcrumb.map(breadCrumb => ({
                name: breadCrumb.name,
                link: modernizeCategoryLink(categoryUrlPath, breadCrumb.url),
            })),
        ]);
    }, [searchResult, categoryUrlPath]);

    useEffect(() => {
        if (document) {
            // Set the page title to the last breadcrumb
            if (breadcrumbs?.[breadcrumbs.length - 1]?.name) {
                updatePageTitle(
                    searchParams,
                    searchResult,
                    breadcrumbs[breadcrumbs.length - 1]!.name
                );
            }
        }
    }, [searchParams, searchResult, breadcrumbs]);

    function getInitialPageName(
        initialValues: Array<{ name: string; link: string }>,
        prefix?: string
    ): pageNameType {
        const initialPagenameValue =
            `${prefix} ${initialValues?.length > 0 && initialValues[1]?.name ? initialValues[1]?.name : ''}`.trim();
        return initialPagenameValue as pageNameType;
    }

    if (isSearchResultsLoading || !breadcrumbs) {
        return null;
    }

    return (
        <Box sx={BreadCrumbContainerStyle}>
            <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement":[${breadcrumbs.map((item, index) => {
                        const listElements = `{
                            "@type": "ListItem",
                            "position": "${index + 1}",
                            "name": "${item.name}",
                            "item": "https://${window.location.hostname + item.link}"
                        }`;
                        return listElements;
                    })}]
                    }`}
            </script>
            <SearchPageDigitalDataLayer
                pageCrumbs={breadcrumbs
                    .filter((_breadCrumb, index) => index > 0)
                    .map(breadCrumb => breadCrumb.name)}
                lang={lang as LocaleType}
                pageName={getInitialPageName(initialValues, 'Category |')}
                site={site}
            />
            <Breadcrumbs
                ariaLabel={arialLabel}
                separator="/"
                breadcrumbElements={breadcrumbs}
            />
            {displaySearchFilters && (
                // Do not display skip-to if there are not filters to skip over
                <SkipToResultsLink
                    skipLinkText={skipLinkText}
                    targetId={SEARCH_RESULT_CONTAINER_ID}
                />
            )}
        </Box>
    );
}
