export const FIRST_ITEM_INDEX_TOKEN = '{{firstDisplayedItemIndex}}';
export const LAST_ITEM_INDEX_TOKEN = '{{lastDisplayedItemIndex}}';
export const TOTAL_ITEMS_TOKEN = '{{totalItems}}';

export const BACK_TO_TOP_LABEL_KEY = 'backtotop';
export const PRODUCT_COUNT_LABEL_KEY = 'productcount';

export const KEYWORDS_KEY = 'search_keyword';
export const SEARCH_MATCH_TYPE_KEY = 'search_match_type';

export const URL_KEY = 'url';

export const CATEGORY_FACET_KEY = 'item_category';

export const product_display_style = {
    product_card_display_style: 'informative',
    show_reviews: true,
    show_action_button: true,
    inventory_aware_listings: true,
}; // TODO: Consider putting this in content stack

export const A11Y_CRITEO_TITLE = 'a11y.criteotitle';
export const A11Y_CRITEO_PRODUCT_TILE_SEPARATOR =
    'a11y.criteoproducttileseparator';
