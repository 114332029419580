export const SEARCH_TOKEN = '{{search term}}';
export const CORRECTED_TOKEN = '{{corrected term}}';
export const RESULT_COUNT_TOKEN = '{{result count}}';
export const FILTER_LABEL_TOKEN = '{{filter label}}';

export const NO_RESULTS_TEXT_KEY = 'title.noresults';
export const DIRECT_MATCH_TEXT_KEY = `title.directmatch`;
export const SINGLE_FILTER_TEXT_KEY = `title.singlefilterheader`;
export const SHOWING_RESULTS_FOR_TEXT_KEY = `title.showingresultsfor`;
export const FOUND_NO_MATCHES_FOR_TEXT_KEY = `title.foundnomatchesfor`;
export const ONLY_PRODUCT_COUNT_TEXT_KEY = `title.onlyproductcount`;
export const NO_MATCH_TEXT_KEY = `title.nomatch`;
export const TRY_ANOTHER_TEXT_KEY = `title.tryanother`;
