'use client';

import { updatePageParam } from '../SearchResultsFacets/utils';

import { useState } from 'react';
import { useTracking } from 'react-tracking';

import { BACK_TO_TOP_LABEL_KEY } from '@/src/containers/main/constants';
import { getKeyValue } from '@/src/services/content/business.lib';
import { useSearchContext } from '@/src/services/search/SearchProvider';
import { ContentStackEntryDataProps } from '@/src/types/contentStack';
import { getIsKeyboardEvent, getIsKeyboardSelect } from '@/src/utils';
import {
    BackToTopLink,
    Divider,
    Pagination,
    Skeleton,
} from '@costcolabs/forge-components';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { Box } from '@mui/system';

export function SearchResultsPagination({
    config,
    handleFocus,
}: {
    config: ContentStackEntryDataProps;
    handleFocus: () => void;
}) {
    const [isKeyboardSelect, setIsKeyboardSelect] = useState(false);
    const { searchResult, isSearchResultsLoading } = useSearchContext();
    const { trackEvent } = useTracking();
    const { queryParams: searchParams, setQueryParams } = useQueryParams();

    /**
     * Function to identify the selected button by checking its aria label
     * @param e
     * @param v
     * @returns
     */
    const getSelectedButton = (e: React.ChangeEvent<any>, v: number) => {
        return e?.currentTarget?.ariaLabel === 'Go to previous page'
            ? 'Previous Arrow'
            : e?.currentTarget?.ariaLabel === 'Go to next page'
              ? 'Next Arrow'
              : v.toString();
    };

    const handlePagination = (e: React.ChangeEvent<any>, v: number) => {
        trackEvent({
            component: {
                identifier: 'Search',
                trackedData: ['Pagination Control', getSelectedButton(e, v)],
                type: 'nav',
            },
            trackType: 'navigation',
        });
        updatePageParam(setQueryParams, searchParams, v.toString());
        if (isKeyboardSelect) {
            setIsKeyboardSelect(false);
            // Set focus to the first product tile on pagination change
            handleFocus();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        // If the pagination event was triggered by a keyboard event,
        // flag that so the pagination handler can set focus on the target element.
        // This is a workaround necessitated by the fact that the pagination event
        // is always a PointerEvent, even when triggered by a keyboard.
        if (getIsKeyboardEvent(e) && getIsKeyboardSelect(e)) {
            setIsKeyboardSelect(true);
            // Clear after 300ms, since the two events should follow one another instantaneously,
            // and there may be subsequent pagination selections.
            setTimeout(() => {
                setIsKeyboardSelect(false);
            }, 300);
        }
    };

    if (isSearchResultsLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: 2,
                }}
            >
                <Skeleton width={35} height={35} />
                <Skeleton width={35} height={35} />
                <Skeleton width={35} height={35} />
            </Box>
        );
    }

    if (!searchResult) {
        return null;
    }

    const { pagination } = searchResult;
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent:
                    searchResult.pagination?.totalPages > 1
                        ? 'space-between'
                        : 'flex-end',
                '@media (max-width: 767px)': {
                    flexDirection: 'column',
                },
                paddingTop: '10px',
            }}
        >
            {pagination?.totalPages > 1 ? (
                <Pagination
                    count={pagination.totalPages}
                    handleChange={handlePagination}
                    handleKeyDown={handleKeyDown}
                    page={
                        searchParams.has('currentPage')
                            ? parseInt(searchParams.get('currentPage')!)
                            : 1
                    }
                    defaultPage={1}
                    sx={{ flexGrow: 1 }}
                />
            ) : (
                <Box />
            )}
            <Divider
                sx={{
                    display: 'none',
                    '@media (max-width: 767px)': {
                        display: 'block',
                        marginTop: '10px',
                    },
                }}
            />
            <Box
                sx={{
                    textWrap: 'nowrap',
                    '@media (max-width: 767px)': {
                        alignSelf: 'end',
                        marginTop: '10px',
                    },
                }}
            >
                <Analytics
                    analyticData={{
                        component: {
                            identifier: 'Search',
                            trackedData: ['Back to Top'],
                            type: 'ui',
                        },
                        trackType: 'navigation',
                    }}
                >
                    <BackToTopLink
                        text={getKeyValue(BACK_TO_TOP_LABEL_KEY, config)}
                    />
                </Analytics>
            </Box>
        </Box>
    );
}
