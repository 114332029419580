'use client';

import { getKeyValue } from '@/services/content/business.lib';
import { useSearchContext } from '@/services/search/SearchProvider';
import type { ContentStackEntryDataProps } from '@/types/contentStack';

import {
    CORRECTED_TOKEN,
    FOUND_NO_MATCHES_FOR_TEXT_KEY,
    SEARCH_TOKEN,
    SHOWING_RESULTS_FOR_TEXT_KEY,
} from './constants';
import { agnosticReplaceTokens } from './utils';

import {
    StyledHeadingTwoPartH1,
    StyledHeadingTwoPartH2,
    StyledHeadingTwoPartParent,
} from './styles';

export const CorrectedResultsHeader = ({
    config,
}: {
    config: ContentStackEntryDataProps;
}) => {
    const { searchResult } = useSearchContext();
    if (!searchResult) {
        return null;
    }
    const { metrics } = searchResult;
    // If no correction or no original search term, return null
    if (!metrics?.correction || !metrics?.original) {
        return null;
    }

    return (
        <StyledHeadingTwoPartParent>
            {/* Showing results for... */}
            <StyledHeadingTwoPartH1>
                {agnosticReplaceTokens(
                    getKeyValue(SHOWING_RESULTS_FOR_TEXT_KEY, config),
                    [
                        {
                            [`${CORRECTED_TOKEN}`]: metrics.correction,
                        },
                    ]
                )}
            </StyledHeadingTwoPartH1>
            {/* We found not matches for... */}
            <StyledHeadingTwoPartH2>
                {agnosticReplaceTokens(
                    getKeyValue(FOUND_NO_MATCHES_FOR_TEXT_KEY, config),
                    [
                        {
                            [`${SEARCH_TOKEN}`]: metrics.original,
                        },
                    ]
                )}
            </StyledHeadingTwoPartH2>
        </StyledHeadingTwoPartParent>
    );
};
