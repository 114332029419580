'use client';

import {
    fullyDecodeURI,
    parseRefineParamToLabels,
} from '../SearchResultsFacets/utils';

import { useState } from 'react';

import { generateClearedParams } from '@/components/ClearAllFilters/utils';
import { nonDefaultRefinementsFilter } from '@/components/Headings/utils';
import { updateSearchParams } from '@/components/SearchResultsFacets/utils';
import { useSearchContext } from '@/services/search/SearchProvider';
import { ContentStackEntryDataProps } from '@/types/contentStack';
import { Modal } from '@costcolabs/forge-components';
import {
    ColorGray100,
    ColorGray700,
    ColorGray900,
    SpaceInsetMd,
} from '@costcolabs/forge-design-tokens';
import { useQueryParams } from '@costcolabs/forge-digital-components';
import { Filter } from '@costcolabs/forge-icons';
import { Box } from '@mui/material';

import { FilterFacetCount, FilterPopupContainer } from './styles';

export function FilterPopup({
    children,
    filterLabel,
    clearAllLabel,
    seeResultsLabel,
    seeSingleResultLabel,
    facetConfig,
}: {
    children: any;
    filterLabel: string;
    clearAllLabel: string;
    seeResultsLabel: string;
    seeSingleResultLabel: string;
    facetConfig: ContentStackEntryDataProps;
}) {
    const { queryParams: searchParamsMap, setQueryParams } = useQueryParams();
    const searchParams = Object.fromEntries(searchParamsMap.entries());
    const [isOpen, setIsOpen] = useState(false);
    const { searchResult } = useSearchContext();

    if (!searchResult) {
        return null;
    }
    let refine = searchParams.refine;
    if (Array.isArray(refine)) {
        refine = refine[0] as string;
    }

    const refinements = parseRefineParamToLabels(
        fullyDecodeURI(refine),
        facetConfig
    );

    // We ignore the default filters
    const nonDefaultRefinements = refinements.filter(
        nonDefaultRefinementsFilter
    );

    const seeResultsButtonLabel =
        searchResult.pagination.totalDocs === 1
            ? seeSingleResultLabel.replace(
                  '{{resultCount}}',
                  String(searchResult.pagination.totalDocs)
              )
            : seeResultsLabel.replace(
                  '{{resultCount}}',
                  String(searchResult.pagination.totalDocs)
              );

    return (
        <Box>
            <FilterPopupContainer
                onClick={() => {
                    setIsOpen(true);
                }}
                variant="secondary"
                startIcon={<Filter />}
                sx={{
                    border: `1px solid ${ColorGray700}`,
                    color: ColorGray900,
                }}
            >
                {filterLabel}
                {nonDefaultRefinements.length ? (
                    <FilterFacetCount>
                        {nonDefaultRefinements.length}
                    </FilterFacetCount>
                ) : (
                    ''
                )}
            </FilterPopupContainer>
            <Modal
                buttons={[
                    {
                        text: seeResultsButtonLabel,
                        action: () => setIsOpen(false),
                    },
                    {
                        text: clearAllLabel,
                        action: () => {
                            updateSearchParams(
                                setQueryParams,
                                generateClearedParams(searchParamsMap)
                            );
                            setIsOpen(false);
                        },
                    },
                ]}
                isOpen={isOpen}
                modalTitle={filterLabel}
                onClose={() => setIsOpen(false)}
                paperProps={{
                    sx: {
                        '&.MuiPaper-root.MuiDialog-paper': {
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                        },
                        '& .MuiDialogActions-root': {
                            padding: SpaceInsetMd,
                            background: ColorGray100,
                            boxShadow: '5px 5px 16px 0px rgba(0, 0, 0, 0.50);',
                        },
                    },
                }}
                scroll="paper"
                sx={{
                    '& > div > div > div:first-of-type': {
                        background: '#3071a9',
                        fontSize: '1.5em',
                        '& h2': {
                            color: '#fff',
                        },
                        '& button svg': {
                            color: '#fff',
                        },
                    },
                }}
            >
                {children}
            </Modal>
        </Box>
    );
}
