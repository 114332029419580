'use client';

import {
    BpLg,
    FontLineHeightMd,
    FontLineHeightSm,
    FontSize400,
    FontSize500,
    FontWeightBold,
    FontWeightDefault,
    SpaceMd,
} from '@costcolabs/forge-design-tokens';
import { styled } from '@mui/material';

const defaultHeadingStyles = {
    fontSize: '0.875rem',
    marginBottom: SpaceMd,
    fontWeight: FontWeightDefault,
    lineHeight: FontLineHeightMd,
    marginTop: 0,
};

export const StyledHeading = styled('h1')({
    ...defaultHeadingStyles,
});

export const StyledHeadingH2 = styled('h2')({
    ...defaultHeadingStyles,
});

export const StyledHeadingTwoPartH1 = styled('h1')({
    ...defaultHeadingStyles,
    fontWeight: FontWeightBold,
    marginBottom: SpaceMd,
    fontSize: FontSize400,
    lineHeight: FontLineHeightSm,
    [`@media (min-width: ${BpLg})`]: {
        fontSize: FontSize500,
    },
});

export const StyledHeadingTwoPartH2 = styled('h2')({
    ...defaultHeadingStyles,
    marginBottom: 0,
    fontSize: FontSize400,
    fontStyle: 'normal',
    lineHeight: FontLineHeightSm,
    [`@media (min-width: ${BpLg})`]: {
        fontSize: FontSize500,
    },
});

export const StyledHeadingTwoPartParent = styled('div')({
    margin: `0`,
});
