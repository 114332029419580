import { ReactNode } from 'react';

import { FilterGroup } from '@/components/FilterGroup';
import { IN_WAREHOUSE_BUCKET_VALUE } from '@/constants/index';
import { SearchServiceProps } from '@/services/search/types';
import {
    FacetBucketNormalized,
    FacetNormalized,
} from '@/src/components/SearchResultsFacets/types';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { PageType } from '@/types/pages/search';
import { Divider } from '@costcolabs/forge-components';
import type { LocaleProps } from '@costcolabs/forge-digital-components';
import { Box } from '@mui/material';

// Facet Label: Hide Unavailable Items
export function ProgramEligibilityFilterGroup({
    facet,
    bopimFacet,
    config,
    WarehouseSelectorComponent,
    DeliveryLocationSelectorComponent,
    locale,
    pageType,
    searchService,
}: {
    facet: FacetNormalized;
    bopimFacet?: FacetNormalized;
    config: ContentStackEntryDataProps;
    WarehouseSelectorComponent: ReactNode;
    DeliveryLocationSelectorComponent: ReactNode;
    locale: LocaleProps;
    pageType: PageType;
    searchService: SearchServiceProps;
}) {
    let InWarehouseCount = 0;
    const facetBucketsInWarehouseRemoved: FacetBucketNormalized[] = [];
    const facetInWarehouseBucket: FacetBucketNormalized[] = [];

    facet.buckets.forEach((bucket: FacetBucketNormalized) => {
        const bucketValLower = String(bucket.val).toLowerCase();
        if (bucketValLower === IN_WAREHOUSE_BUCKET_VALUE) {
            InWarehouseCount = bucket.count;
            facetInWarehouseBucket.push(bucket);
        } else {
            facetBucketsInWarehouseRemoved.push(bucket);
        }
    });

    const facetInWarehouseRemoved = {
        ...facet,
        buckets: facetBucketsInWarehouseRemoved,
    };

    const facetInWarehouse = {
        ...facet,
        buckets: facetInWarehouseBucket,
    };

    return (
        <>
            <Divider />
            <Box
                sx={{
                    padding: '10px 0',
                    '& button': { fontSize: 14, textDecoration: 'underline' },
                }}
            >
                {DeliveryLocationSelectorComponent}
            </Box>
            <FilterGroup
                facet={facetInWarehouseRemoved}
                config={config}
                locale={locale}
                pageType={pageType}
                searchService={searchService}
            />

            {bopimFacet && (
                <FilterGroup
                    facet={bopimFacet}
                    config={config}
                    locale={locale}
                    pageType={pageType}
                    searchService={searchService}
                />
            )}
            {InWarehouseCount > 0 && (
                <>
                    <Box
                        sx={{
                            paddingBottom: '10px',
                            '& button': {
                                fontSize: 14,
                                textDecoration: 'underline',
                            },
                        }}
                    >
                        {WarehouseSelectorComponent}
                    </Box>
                    <FilterGroup
                        facet={facetInWarehouse}
                        config={config}
                        locale={locale}
                        pageType={pageType}
                        searchService={searchService}
                    />
                </>
            )}
        </>
    );
}
