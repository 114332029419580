import { updateGRSRefinements } from '@/components/SearchResultsFacets/grs.lib';
import { updateLWRefinements } from '@/components/SearchResultsFacets/utils';
import {
    FacetBucketNormalized,
    FacetNormalized,
} from '@/src/components/SearchResultsFacets/types';
import { ITEM_LOCATION_PRICING_FACET_KEY } from '@/src/constants';
import { useQueryParams } from '@costcolabs/forge-digital-components';

interface UseRefinementProps {
    searchService: 'lucidworks' | 'grs';
}

const useRefinement = ({ searchService }: UseRefinementProps) => {
    const { queryParams: searchParams, setQueryParams } = useQueryParams();

    const handleDoRefine = (
        facet: FacetNormalized,
        bucket: FacetBucketNormalized,
        isEnabled: boolean
    ) => {
        if (searchService === 'lucidworks') {
            const refinementPair =
                facet.facetKey === ITEM_LOCATION_PRICING_FACET_KEY
                    ? `${facet.facetKey}-[${bucket.low} TO ${bucket.high}]`
                    : `${facet.facetKey}-${bucket.val}`;

            updateLWRefinements(
                setQueryParams,
                refinementPair,
                isEnabled,
                searchParams
            );
        } else if (searchService === 'grs') {
            updateGRSRefinements(
                setQueryParams,
                facet,
                bucket,
                isEnabled,
                searchParams
            );
        }
    };

    return { handleDoRefine };
};

export default useRefinement;
