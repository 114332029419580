'use client';

import { ReactNode } from 'react';

import { fullyDecodeURI } from '@/src/components/SearchResultsFacets/utils';
import { useSearchContext } from '@/src/services/search/SearchProvider';
import { ContentStackEntryDataProps } from '@/src/types/contentStack';
import type { LocaleProps } from '@costcolabs/forge-digital-components';
import { CriteoProviderClientComponent } from '@costcolabs/forge-digital-components';

import { createCriteoSearchFiltersString } from './utils';

export default function CriteoProviderWrapper({
    productTileConfigData,
    criteoAPIConfigurationSettings,
    lang,
    children,
    categoryId,
    isCategoryPage,
}: {
    productTileConfigData: ContentStackEntryDataProps;
    criteoAPIConfigurationSettings: ContentStackEntryDataProps;
    lang: LocaleProps;
    children: ReactNode;
    categoryId?: string;
    isCategoryPage: boolean;
}) {
    const { searchResult, searchedParams, isSearchResultsLoading } =
        useSearchContext();

    let refine = searchedParams?.refine;
    if (Array.isArray(refine)) {
        refine = refine[0];
    }

    const categoryHasSearchResults =
        isCategoryPage && categoryId && !isSearchResultsLoading && searchResult;

    return (
        <CriteoProviderClientComponent
            productTileConfigData={productTileConfigData}
            criteoAPIConfigurationSettings={criteoAPIConfigurationSettings}
            locale={lang}
            categoryId={categoryId}
            searchResult={searchResult}
            isCategoryPage={isCategoryPage}
            searchParams={{
                ...(searchedParams || {}),
                refine: createCriteoSearchFiltersString(
                    fullyDecodeURI(refine),
                    !isCategoryPage ? categoryId : undefined
                ),
            }}
            isSearchResultsLoading={isSearchResultsLoading}
            listSize={24}
            isTargetingDisabled={
                !isCategoryPage || categoryHasSearchResults
                    ? !searchResult?.isAdTargetingEnabled
                    : false
            }
        >
            {children}
        </CriteoProviderClientComponent>
    );
}
