'use client';

import { useEffect } from 'react';

import {
    FILTER,
    FILTER_VAL,
    SHOP_BY_PREFIX,
    TRACK_TYPE_MULTI_ATTR,
} from '@/components/Analytics/constants';
import { prependCategoryUrl, updatePageTitle } from '@/services/search/utils';
import { getCategoryAttributes } from '@/src/components/SearchResultsFacets/grs.lib';
import { FacetNormalized } from '@/src/components/SearchResultsFacets/types';
import { getKeyValue } from '@/src/services/content/business.lib';
import type { ContentStackEntryDataProps } from '@/types/contentStack';
import { Link, List, Text } from '@costcolabs/forge-components';
import { ColorTextDefault, SpaceXs } from '@costcolabs/forge-design-tokens';
import {
    Analytics,
    useQueryParams,
} from '@costcolabs/forge-digital-components';
import { ChevronLeft } from '@costcolabs/forge-icons';
import { Box } from '@mui/material';

import {
    checkCurrentCategory,
    getFilteredCategories,
} from './categoriesGRS.view.lib';
import {
    CHILD_CATEGORY_A11Y_TEXT,
    PARENT_CATEGORY_A11Y_TEXT,
} from './constants';

export function CategoryGRSFilterView({
    categoryUrlPath,
    facet,
    resultsText,
    config,
    categoryId,
}: {
    categoryUrlPath: string | undefined;
    facet: FacetNormalized;
    resultsText: string;
    config: ContentStackEntryDataProps;
    categoryId?: string;
}) {
    const { queryParams: params } = useQueryParams();
    const showMoreText = getKeyValue('facet.showmoretext', config);

    const currentParameters: string[] = [];
    params.forEach((value, key) => {
        currentParameters.push(`${key}=${encodeURIComponent(value)}`);
    });
    const combinedParameters = currentParameters.join('&');

    const selectedCategory = facet.buckets.find(bucket =>
        checkCurrentCategory(bucket, categoryId)
    );

    const selectedCategoryAttributes =
        selectedCategory &&
        getCategoryAttributes(selectedCategory?.val.toString());

    const filteredBuckets = getFilteredCategories(facet, categoryId);

    useEffect(() => {
        if (selectedCategoryAttributes?.label) {
            updatePageTitle(
                params,
                undefined,
                selectedCategoryAttributes?.label
            );
        }
    }, [selectedCategoryAttributes?.label]);

    if (!facet.buckets) {
        return null;
    }

    return (
        <>
            {/* @ts-ignore TODO */}
            <List
                variant="ExpandableList"
                showMoreText={showMoreText}
                ariaLabeledById={facet.facetKey}
                buttonId={`show_more_${facet.label.toLowerCase().split(' ').join('_')}`}
                defaultItemsToExpose={10}
            >
                {filteredBuckets.map(bucket => {
                    if (!bucket.val || !bucket.label) {
                        return null;
                    }

                    const isParentCategory = Boolean(
                        bucket.level &&
                            selectedCategoryAttributes?.level &&
                            bucket.level < selectedCategoryAttributes.level
                    );

                    return (
                        <>
                            {!bucket.isSelectedCateogry && (
                                <Analytics
                                    key={bucket.val.toString()}
                                    analyticData={{
                                        trackedData: [
                                            {
                                                key: FILTER,
                                                value:
                                                    SHOP_BY_PREFIX +
                                                    facet.label,
                                            },
                                            {
                                                key: FILTER_VAL,
                                                value: bucket.label ?? '',
                                            },
                                        ],
                                        trackType: TRACK_TYPE_MULTI_ATTR,
                                        doNotTrack: bucket.leads_to_fsa_chdi,
                                    }}
                                >
                                    <Box
                                        key={bucket.val.toString()}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flext-start',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {/** Category links always route to the .html page with just a keyword */}
                                        <Link
                                            automation-id={`${facet.facetKey}`}
                                            href={prependCategoryUrl(
                                                categoryUrlPath,
                                                bucket.meta ?? '',
                                                combinedParameters
                                            )}
                                            underline="always"
                                            aria-label={
                                                isParentCategory
                                                    ? `${bucket.label}, ${getKeyValue(PARENT_CATEGORY_A11Y_TEXT, config)}`
                                                    : `${bucket.label}, ${getKeyValue(CHILD_CATEGORY_A11Y_TEXT, config)} ${bucket.count.toLocaleString()} ${resultsText}`
                                            }
                                            sx={{
                                                color: ColorTextDefault,
                                                textDecoration: 'underline',
                                                display: 'flex',
                                                justifyContent: isParentCategory
                                                    ? 'flex-start'
                                                    : 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            {isParentCategory && (
                                                <ChevronLeft
                                                    sx={{
                                                        paddingRight: SpaceXs,
                                                        color: ColorTextDefault,
                                                    }}
                                                />
                                            )}
                                            <Box component={'span'}>
                                                {bucket.label}
                                            </Box>
                                            {!isParentCategory && (
                                                <Box
                                                    component={'span'}
                                                    id={`${facet.facetKey}-${bucket.val.toString()}`}
                                                    sx={{
                                                        color: 'rgb(51, 51, 51)',
                                                    }}
                                                    aria-hidden={true}
                                                >
                                                    (
                                                    {bucket.count.toLocaleString()}
                                                    )
                                                </Box>
                                            )}
                                        </Link>
                                    </Box>
                                </Analytics>
                            )}
                            {bucket.isSelectedCateogry && (
                                <Text
                                    variant="t5"
                                    sx={{
                                        color: ColorTextDefault,
                                        paddingLeft: '26px',
                                    }}
                                    aria-current="page"
                                >
                                    {bucket.label}
                                </Text>
                            )}
                        </>
                    );
                })}
            </List>
        </>
    );
}
